import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import { LinearProgress, IconButton, Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckIcon from '@mui/icons-material/Check';

import { scrollToElementInPage } from '../../api/dataApi';
import { listAllCalendarEvent } from '../../api/propertyProfileApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import CustomerEventCalendar from '../CustomerEventCalendar';
import GeneralPopup from '../GeneralPopup';
import ResidentOnboardingPopup from '../ResidentOnboarding/ResidentOnboardingPopup';

import './index.css';

function PropertyCalendar({ profileKey }) {
  const [params, setParams] = useState(null);
  const [allEvents, setAllEvents] = useState(null);
  const [events, setEvents] = useState(null);
  const [showCalendar, setShowCalendar] = useState(null);
  const [loading, setLoading] = useState(null);
  const [showResidentOnboarding, setShowResidentOnboarding] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    setParams(getParams());
  }, []);

  useEffect(() => {
    if (params) {
      loadEvents();
    }
  }, [params]);

  useEffect(() => {
    if (allEvents && selectedCategory) {
      let _events = {};

      Object.keys(allEvents).map((date) =>
        Object.keys(allEvents[date]).map((unitNumber) =>
          allEvents[date][unitNumber].map((event) => {
            if (
              selectedCategory[event.CalendarName] &&
              selectedCategory[event.CalendarName].selected
            ) {
              if (!_events[date]) {
                _events[date] = {};
              }

              if (!_events[date][unitNumber]) {
                _events[date][unitNumber] = [];
              }

              _events[date][unitNumber].push(event);
            }
          })
        )
      );

      setEvents(_events);
    }
  }, [allEvents, selectedCategory]);

  function loadEvents() {
    setLoading(true);
    listAllCalendarEvent(
      profileKey,
      params.startDate.format('YYYY-MM-DD'),
      params.endDate.format('YYYY-MM-DD')
    ).then((_events) => {
      setAllEvents(_events);
      setLoading(false);

      let _selectedCategory = {};

      Object.keys(_events).map((date) =>
        Object.keys(_events[date]).map((unitNumber) => {
          _events[date][unitNumber].map((event) => {
            _selectedCategory[event.CalendarName] =
              selectedCategory && selectedCategory[event.CalendarName]
                ? { ...selectedCategory[event.CalendarName] }
                : {
                    selected: true,
                    backgroundColor: event.BackgroundColor,
                    textColor: event.TextColor
                  };
          });
        })
      );

      setSelectedCategory(_selectedCategory);
    });
  }

  function getParams(direction) {
    let monthYear =
      direction && params
        ? params.monthYear.add(direction, 'month')
        : moment().startOf('month');

    //let startDate = moment(monthYear.format('YYYY-MM-DD')).startOf('month');
    //let endDate = moment(monthYear.format('YYYY-MM-DD')).endOf('month');
    let startDate = params
      ? moment(params.startDate.format('YYYY-MM-DD'))
          .add('week', 1)
          .add('week', direction * 5)
      : moment(new Date());

    //Find the first Sunday on or before the first of the month
    if (startDate.day() !== 0) {
      while (startDate.day() !== 0) {
        startDate.add(-1, 'day');
      }
    }

    startDate.add(-1, 'week');

    let endDate = moment(startDate.format('YYYY-MM-DD')).add('week', 5);

    while (endDate.day() !== 6) {
      endDate.add(1, 'day');
    }

    endDate.add(1, 'week');

    let dateList = [];
    let week = [];
    let _date = moment(startDate.format('YYYY-MM-DD'));
    while (_date.isSameOrBefore(endDate)) {
      week.push(_date.format('YYYY-MM-DD'));

      if (week.length === 7) {
        dateList.push(week);
        week = [];
      }

      _date.add(1, 'day');
    }

    return { monthYear, startDate, endDate, dateList };
  }

  function handleEventClick(event) {
    if (event.CalendarID) {
      setShowCalendar({ ...event });
    } else {
      setShowResidentOnboarding({ ...event });
    }
  }

  function getCategoryDisplayOrder(category) {
    switch (category) {
      case 'READY for Move-in':
        return 'AAAAA-' + category;

      case 'NOT READY for Move-in':
        return 'BBBBB-' + category;
      case 'Move Out':
        return 'CCCCC-' + category;
      default:
        return 'ZZZZZ-' + category;
    }
  }

  return (
    <div className={'property-calendar-list'}>
      {params && params.dateList ? (
        <div className="property-calendar">
          <h2>
            <IconButton onClick={() => setParams(getParams(-1))}>
              <ChevronLeftIcon />
            </IconButton>{' '}
            <span>{moment(params.monthYear).format('MMMM YYYY')} </span>
            <IconButton onClick={() => setParams(getParams(1))}>
              <ChevronRightIcon />
            </IconButton>
          </h2>

          {selectedCategory ? (
            <div className="category-list">
              {Object.keys(selectedCategory)
                .sort((a, b) =>
                  getCategoryDisplayOrder(a).localeCompare(
                    getCategoryDisplayOrder(b)
                  )
                )
                .map((categoryKey) => (
                  <Button
                    style={{
                      backgroundColor:
                        selectedCategory[categoryKey].backgroundColor,
                      color: selectedCategory[categoryKey].textColor
                    }}
                    onClick={() =>
                      setSelectedCategory({
                        ...selectedCategory,
                        [categoryKey]: {
                          ...selectedCategory[categoryKey],
                          selected: !selectedCategory[categoryKey].selected
                        }
                      })
                    }
                    className={
                      selectedCategory[categoryKey].selected ? ' selected' : ''
                    }>
                    <CheckIcon /> {categoryKey}
                  </Button>
                ))}
            </div>
          ) : null}

          <div className="calendar">
            {loading ? <LinearProgress /> : null}
            <div className="month">
              {params.dateList.map((week, weekIndex) => (
                <div className="week">
                  {week.map((day) => (
                    <div
                      className={
                        'day' +
                        (!moment(day).isSameOrAfter(new Date(), 'day')
                          ? ' past'
                          : '')
                      }>
                      <h4>
                        {weekIndex === 0 ? (
                          <em>{moment(day).format('ddd').toUpperCase()}</em>
                        ) : null}

                        {moment(day).format('D') == '1'
                          ? moment(day).format('MMM') + ' '
                          : null}
                        {moment(day).format('D')}
                      </h4>

                      {events && events[day]
                        ? Object.keys(events[day]).map((unitNumber) => (
                            <div className="event-group">
                              <Button
                                onClick={() =>
                                  handleEventClick(events[day][unitNumber][0])
                                }
                                className="event"
                                style={{
                                  backgroundColor:
                                    events[day][unitNumber][0].BackgroundColor,
                                  color: events[day][unitNumber][0].TextColor
                                }}>
                                {unitNumber ? unitNumber + ' - ' : ''}
                                {events[day][unitNumber][0].EventName}{' '}
                                {events[day][unitNumber][0].StartTime
                                  ? ' at ' +
                                    events[day][unitNumber][0].StartTime
                                  : ''}
                              </Button>
                              {events[day][unitNumber].length > 1
                                ? events[day][unitNumber].map(
                                    (event, eventIndex) =>
                                      eventIndex ? (
                                        <>
                                          <div className="connector"></div>
                                          <Button
                                            onClick={() =>
                                              handleEventClick(event)
                                            }
                                            className="event sub"
                                            style={{
                                              backgroundColor:
                                                event.BackgroundColor,
                                              color: event.TextColor
                                            }}>
                                            {event.EventName}
                                            {event.StartTime
                                              ? ' at ' + event.StartTime
                                              : ''}
                                          </Button>
                                        </>
                                      ) : null
                                  )
                                : null}
                            </div>
                          ))
                        : null}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <LinearProgress />
      )}

      {showCalendar ? (
        <GeneralPopup
          title={showCalendar.CalendarName}
          message={<CustomerEventCalendar calendar={showCalendar} />}
          closeFunc={() => {
            loadEvents();
            setShowCalendar(null);
          }}
          closeLabel={'Close'}
          className={'calendar-timeslot-popup'}
        />
      ) : null}

      {showResidentOnboarding ? (
        <ResidentOnboardingPopup
          residentID={showResidentOnboarding.ResidentID}
          includeChecklist
          closeFunc={() => {
            loadEvents();
            setShowResidentOnboarding(null);
          }}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyCalendar);
