import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputMask from 'react-input-mask';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import SearchIcon from '@mui/icons-material/Search';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckIcon from '@mui/icons-material/Check';

import Switch from '@mui/material/Switch';

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
  Alert,
  IconButton,
  menuClasses,
  Tooltip,
  useEventCallback
} from '@mui/material';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';

import * as propertyProfileApi from '../../api/propertyProfileApi';
import * as dataApi from '../../api/dataApi';
import {
  registerUser,
  validatePassword,
  PasswordPolicy,
  logIn
} from '../../api/userAuthApi';

import '../ServiceProfileShared/index.css';
import './index.css';

import UtilityBox from '../ConnectUtilities/UtilityBox';
import CanvasConfetti from '../CanvasConfetti';
import OnboardingDashboard from '../OnboardingDashboard';
import PropertyProfileDefaults from './PropertyProfileDefaults';
import PropertyUsers from './PropertyUsers';
import PropertyPets from '../PropertyPets';
import PropertyVehicles from '../PropertyVehicles';
import PropertyContact from './PropertyContact';
import UserForgotPassword from '../UserForgotPassword';
import LoginFormPopup from '../LoginForm/LoginFormPopup';
import RequiredTasks from './RequiredTasks';
import UserPropertySelect from '../UserPropertySelect';
import PropertyCalendar from '../PropertyCalendar';
import SCPropertyDashboard from './SCPropertyDashboard';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import { setSelectedResident } from '../UserPropertySelect';

import {
  QontoConnector,
  QontoStepIconRoot,
  QontoStepIcon,
  CircularProgressWithLabel,
  dayOfWeekList,
  businessHourList
} from '../ServiceProfileShared/config';
import StepServicesRequired from '../ServiceProfileShared/StepServicesRequired';
import StepInternetOptions from '../ServiceProfileShared/StepInternetOptions';
import StepRentersInsurance from '../ServiceProfileShared/StepRentersInsurance';
import StepSchools from '../ServiceProfileShared/StepSchools';

import RadioOption from '../ServiceProfileShared/RadioOption';
import ProofOfServiceOption from '../ServiceProfileShared/ProofOfServiceOption';

import OtherPartnerField from './OtherPartnerField';

import ResidentInternetHeader from '../ResidentInternetHeader';

function PropertyInfoEdit({
  serviceProfile,
  setServiceProfile,
  stateList,
  siteConfig
}) {
  return (
    <>
      <p>Please enter or confirm the following about your property.</p>

      <TextField
        value={serviceProfile.Header.ManagementCompanyEntered}
        label="Management Company"
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              ManagementCompanyEntered: event.target.value
            }
          })
        }
        fullWidth
      />

      <TextField
        value={serviceProfile.Header.PropertyNameEntered}
        label="Property Name"
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              PropertyNameEntered: event.target.value
            }
          })
        }
        fullWidth
      />

      <TextField
        value={serviceProfile.Header.PropertyAddressEntered}
        label="Property Address"
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              PropertyAddressEntered: event.target.value
            }
          })
        }
        fullWidth
      />

      <TextField
        value={serviceProfile.Header.PropertyCityEntered}
        label="City"
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              PropertyCityEntered: event.target.value
            }
          })
        }
        className="city"
        fullWidth
      />

      <FormControl variant="outlined" className="state">
        <InputLabel id="property-state-label">State</InputLabel>
        <Select
          labelId="property-state-label"
          label="State"
          id="property-state-label"
          required
          value={serviceProfile.Header.PropertyStateEntered}
          onChange={(event) =>
            setServiceProfile({
              ...serviceProfile,
              Header: {
                ...serviceProfile.Header,
                PropertyStateEntered: event.target.value
              }
            })
          }>
          {stateList.map((state) => (
            <MenuItem value={state}>{state}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        value={serviceProfile.Header.PropertyZipEntered}
        label="Zip"
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              PropertyZipEntered: event.target.value
            }
          })
        }
        className="zip"
        type="number"
      />

      <InputMask
        mask="999-999-9999"
        maskChar="_"
        value={serviceProfile.Header.PropertyPhoneEntered}
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              PropertyPhoneEntered: event.target.value
            }
          })
        }>
        {() => (
          <TextField
            variant="outlined"
            id="phone"
            name="phone"
            label={'Property Phone Number'}
            type="tel"
            fullWidth
          />
        )}
      </InputMask>

      <TextField
        value={serviceProfile.Header.UnitCountEntered}
        label="Number of Residential Units"
        fullWidth
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              UnitCountEntered: event.target.value
            }
          })
        }
        type="number"
        className="unit-count"
      />

      <p>
        Onsite Contact - who should {siteConfig.brand} contact if we have any
        questions regarding this property?
      </p>

      <TextField
        value={serviceProfile.Header.OnsiteContactFirstName}
        label="Onsite Contact First Name"
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              OnsiteContactFirstName: event.target.value
            }
          })
        }
        fullWidth
      />

      <TextField
        value={serviceProfile.Header.OnsiteContactLastName}
        label="Onsite Contact Last Name"
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              OnsiteContactLastName: event.target.value
            }
          })
        }
        fullWidth
      />

      <TextField
        value={serviceProfile.Header.OnsiteContactTitle}
        label="Onsite Contact Title"
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              OnsiteContactTitle: event.target.value
            }
          })
        }
        fullWidth
      />

      <TextField
        value={serviceProfile.Header.OnsiteContactEmail}
        label="Onsite Contact Email"
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              OnsiteContactEmail: event.target.value
            }
          })
        }
        type="email"
        fullWidth
      />

      <InputMask
        mask="999-999-9999"
        maskChar="_"
        value={serviceProfile.Header.OnsiteContactPhone}
        onChange={(event) =>
          setServiceProfile({
            ...serviceProfile,
            Header: {
              ...serviceProfile.Header,
              OnsiteContactPhone: event.target.value
            }
          })
        }>
        {() => (
          <TextField
            variant="outlined"
            id="phone"
            name="phone"
            label={'Onsite Contact Phone Number'}
            type="tel"
            fullWidth
          />
        )}
      </InputMask>
    </>
  );
}

function StorageParkingOptions({ setProviderServiceValue, providerService }) {
  return (
    <div className="form">
      <div className="section">
        <h3>Storage - Parking</h3>
        <Alert severity="info">
          Does your community offer additional Storage Units, Garages or Parking
          Spaces for rent? If you select YES below, we'll add a note to the
          resident dashboard letting residents know how to contact the property
          directly, should they need any of these.
        </Alert>
        <div className="form-content">
          <RadioOption
            setProviderServiceValue={setProviderServiceValue}
            providerService={providerService}
            field={'StorageHasOnsite'}
            question={'Storage Units'}></RadioOption>

          <RadioOption
            setProviderServiceValue={setProviderServiceValue}
            providerService={providerService}
            field={'StorageHasGarages'}
            question={'Garages'}></RadioOption>

          <RadioOption
            setProviderServiceValue={setProviderServiceValue}
            providerService={providerService}
            field={'StorageHasParking'}
            question={'Parking Spaces'}></RadioOption>

          {/* <RadioOption
        setProviderServiceValue={
          setProviderServiceValue
        }
        providerService={providerService}
        disabled={
          !providerService.StorageHasOnsite &&
          !providerService.StorageHasGarages &&
          !providerService.StorageHasParking
        }
        field={'StorageIsInSoftware'}
        question={
          'If YES, do you manage the rental of these spaces in the same Property Management platform as your residential units?'
        }></RadioOption>

      <RadioOption
        setProviderServiceValue={
          setProviderServiceValue
        }
        providerService={providerService}
        field={'StorageShowOnsite'}
        disabled={
          (!providerService.StorageHasOnsite &&
            !providerService.StorageHasGarages &&
            !providerService.StorageHasParking) ||
          !providerService.StorageIsInSoftware
        }
        question={
          'If YES, should we show available spaces in our resident dashboard to remind residents that you have them?'
        }></RadioOption> */}
        </div>
      </div>
    </div>
  );
}

export function PropertyHeader({
  property,
  isCompleted,
  activeRoute,
  updateRoute,
  setShowRestoreDefaults,
  getPropertyName,
  searchString,
  setSearchString,
  siteConfig,
  setShowPropertySelect,
  profileKey,
  goToResidentDashboard,
  userHasAccess,
  leftTab,
  rightTab,
  tabCounts,
  serviceProfile,
  userIsReadOnly,
  authUser
}) {
  return (
    <>
      <div
        className={
          'property-header' +
          (activeRoute !== '/property-onboarding' ? ' editable' : '')
        }>
        {/* <IconButton
    className="property-info-edit"
    onClick={() => setPropertyInfoEditMode(true)}>
    <EditIcon />
  </IconButton> */}

        <div className="property-secondary-nav">
          <Button
            className="contact"
            onClick={() => updateRoute('/property-contact')}>
            <Tooltip title="Questions and Contact Us" placement="left">
              <ContactSupportIcon />
            </Tooltip>
          </Button>
          <br />

          {activeRoute === '/property-setup' ? (
            <>
              {/* <Button
                className="restore-defaults"
                variant="outlined"
                color="secondary"
                onClick={() => setShowRestoreDefaults('default')}>
                Restore Defaults
              </Button> */}

              {userHasAccess() ? (
                <Button
                  className="clear-form"
                  variant="outlined"
                  color="secondary"
                  onClick={() => setShowRestoreDefaults('clear')}>
                  Clear Form
                </Button>
              ) : null}
            </>
          ) : null}
        </div>
        <div className="property-admin-nav">
          {property && property.PropertyID ? (
            <>
              {(isCompleted || siteConfig.id === 'sc') && userHasAccess() ? (
                <>
                  {!userIsReadOnly() &&
                  (siteConfig.id === 'mi' || authUser.User.IsAdmin) ? (
                    <>
                      <Button
                        onClick={() => {
                          updateRoute('/property-users');
                        }}>
                        <ManageAccountsIcon /> <span>User Access Controls</span>
                      </Button>

                      {siteConfig.id === 'mi' ? (
                        <Button
                          className="edit-setup"
                          onClick={() => {
                            updateRoute('/property-setup');
                          }}>
                          <SettingsIcon /> <span>Edit Property Setup</span>
                        </Button>
                      ) : (
                        <Button
                          className="edit-setup"
                          onClick={() => {
                            updateRoute('/property-setup');
                          }}>
                          <DashboardIcon /> <span>View Property Dashboard</span>
                        </Button>
                      )}
                    </>
                  ) : null}

                  {siteConfig.id === 'mi' ? (
                    <Button
                      onClick={() => {
                        goToResidentDashboard();
                      }}>
                      <DashboardIcon /> <span>View Resident Dashboard</span>
                    </Button>
                  ) : null}
                </>
              ) : activeRoute !== '/property-setup' ? (
                <Button
                  onClick={() => {
                    updateRoute('/property-setup');
                  }}>
                  <ChevronLeftIcon />
                  Back
                </Button>
              ) : null}
            </>
          ) : null}
        </div>

        <div className="property-info">
          <h2>{getPropertyName ? getPropertyName() : property.PropertyName}</h2>
          {property.PropertyID ? (
            <div className="address">
              {property.Address}, {property.City}, {property.State}{' '}
              {property.Zip}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mobile-notice">
        <Alert severity="warning">
          This section of {siteConfig.brand} is optimized to view on an office
          PC with a screen size of 1024px or greater. Please use a larger screen
          or desktop computer.
        </Alert>
      </div>

      {isCompleted && userHasAccess() && siteConfig.id === 'mi' ? (
        <div className="tabs-container">
          {leftTab ? (
            <Button
              className={
                'faux-tab pinned-left' +
                (activeRoute === leftTab.route ? ' active' : '')
              }
              onClick={() => updateRoute(leftTab.route)}>
              {leftTab.label}
              {tabCounts && tabCounts[leftTab.route] ? (
                <span className="notification-count">
                  {tabCounts[leftTab.route]}
                </span>
              ) : null}
            </Button>
          ) : null}

          {rightTab ? (
            <Button
              className={
                'faux-tab pinned-right' +
                (activeRoute === rightTab.route ? ' active' : '')
              }
              onClick={() => updateRoute(rightTab.route)}>
              {rightTab.label}

              {tabCounts && tabCounts[rightTab.route] ? (
                <span className="notification-count">
                  {tabCounts[rightTab.route]}
                </span>
              ) : null}
            </Button>
          ) : null}

          <Tabs
            value={activeRoute}
            onChange={(event, newValue) => {
              updateRoute(newValue);
            }}
            centered
            sx={{ margin: 'auto' }}>
            <Tab
              label={
                <>
                  {'5-day Snapshot'}
                  {tabCounts && tabCounts['/property-events'] ? (
                    <span className="notification-count">
                      {tabCounts['/property-events']}
                    </span>
                  ) : null}
                </>
              }
              value={'/property-events'}
            />
            <Tab
              label={
                <>
                  {siteConfig.brand + ' Dashboard'}
                  {tabCounts && tabCounts['/property-onboarding'] ? (
                    <span className="notification-count">
                      {tabCounts['/property-onboarding']}
                    </span>
                  ) : null}
                </>
              }
              value={'/property-onboarding'}
            />
            <Tab label="Vehicle Registry" value={'/property-vehicles'} />
            <Tab label="Pet Profiles" value={'/property-pets'} />
            <Tab label="Calendar" value={'/property-calendars'} />
            <Tab
              className="search"
              label={
                <FormControl variant="standard">
                  <Input
                    id="input-with-icon-adornment"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    placeholder={'Search'}
                    value={searchString}
                    onChange={(event) => setSearchString(event.target.value)}
                  />
                </FormControl>
              }
              value={activeRoute}
            />
          </Tabs>
        </div>
      ) : null}
    </>
  );
}

function PropertyProfile({
  actions,
  serviceAddress,
  siteConfig,
  webSocket,
  stateList,
  authUser
}) {
  const loadProfileInitted = useRef();
  const scrollPositionInitted = useRef();
  const [activeRoute, setActiveRoute] = useState(dataApi.getCurrentRoute());
  const profileKey = useQueryParam('property', null);
  const [propertyProfile, setPropertyProfile] = useState(null);
  const [activeStep, setActiveStep] = React.useState(null);
  const [serviceProfile, setServiceProfile] = useState(null);
  const saveTimeout = useRef();
  const initted = useRef();
  const [isCompleted, setIsCompleted] = useState(false);
  const [justCompleted, setJustCompleted] = useState(false);
  const [alreadyCompleted, setAlreadyCompleted] = useState(false);

  const [propertyInfoEditMode, setPropertyInfoEditMode] = useState(null);
  const [showRestoreDefaults, setShowRestoreDefaults] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [userError, setUserError] = useState(null);
  const [showPasswordHelp, setShowPasswordHelp] = useState(null);
  const [showLoginForm, setShowLoginForm] = useState(null);
  const [showPropertySelect, setShowPropertySelect] = useState(null);

  const serviceProfileLoaded = useRef();
  const changesMade = useRef();
  const [leftTab, setLeftTab] = useState(null);
  const [rightTab, setRightTab] = useState(null);
  const [tabCounts, setTabCounts] = useState(null);

  useEffect(() => {
    if (
      profileKey &&
      webSocket.connected &&
      loadProfileInitted.current !== profileKey
    ) {
      loadProfileInitted.current = profileKey;
      actions.clearResidentOnboarding();
      loadPropertyProfile();
    }
  }, [profileKey, webSocket]);

  useEffect(() => {
    if (serviceProfile) {
      if (serviceProfileLoaded.current) {
        changesMade.current = true;
      }

      serviceProfileLoaded.current = true;
    }

    if (serviceProfile && serviceProfile.Header) {
      setRightTab(
        serviceProfile.Header.UseNoticeProcess
          ? { label: 'On Notice', route: '/property-notice' }
          : null
      );
    }

    if (serviceProfile && serviceProfile.Header) {
      setLeftTab(
        serviceProfile.Header.UseApplicantProcess
          ? { label: 'Applicants', route: '/property-applicants' }
          : null
      );
    }

    console.log('serviceProfile', serviceProfile);
  }, [serviceProfile]);

  useEffect(() => {
    if (propertyProfile) {
      if (propertyProfile.ServiceProfile) {
        setServiceProfile({
          ...propertyProfile.ServiceProfile,
          ServiceSectionList: propertyProfile.ServiceProfile.ServiceSectionList
            ? propertyProfile.ServiceProfile.ServiceSectionList.map(
                (serviceSection) => {
                  return {
                    ...serviceSection,
                    ProviderServiceList: serviceSection.ProviderServiceList.map(
                      (providerService) => {
                        const preferredProvider =
                          serviceSection.ProviderList.filter(
                            (provider) =>
                              provider.ProviderServiceID ===
                                providerService.ProviderServiceID &&
                              provider.IsProviderPreferred
                          )[0];

                        return {
                          ...providerService,
                          preferredProviderID: preferredProvider
                            ? preferredProvider.ProviderID
                            : providerService.ProviderNameEntered
                            ? 0
                            : null
                        };
                      }
                    )
                  };
                }
              )
            : []
        });
      }

      const _isCompleted =
        propertyProfile &&
        propertyProfile.ServiceProfile &&
        propertyProfile.ServiceProfile.Header &&
        propertyProfile.ServiceProfile.Header.ServiceProfileStatusID > 1;

      setIsCompleted(_isCompleted);
      setAlreadyCompleted(_isCompleted);

      if (propertyProfile.Property) {
        if (typeof window !== 'undefined') {
          document.title =
            (getPropertyName() ? getPropertyName() : 'Custom Property Setup') +
            ' - MovingIN';
        }
      }

      if (
        propertyProfile &&
        propertyProfile.ServiceProfile &&
        propertyProfile.ServiceProfile.Header &&
        propertyProfile.ServiceProfile.Header.ActiveStep !== null &&
        propertyProfile.ServiceProfile.Header.ActiveStep >= 0 &&
        propertyProfile.ServiceProfile.ServiceSectionList &&
        propertyProfile.ServiceProfile.Header.ActiveStep <
          propertyProfile.ServiceProfile.ServiceSectionList.length &&
        propertyProfile.Property &&
        propertyProfile.Property.UserHasAccess
      ) {
        setActiveStep(propertyProfile.ServiceProfile.Header.ActiveStep);
      }
    }
  }, [propertyProfile]);

  useEffect(() => {
    if (activeStep !== null) {
      if (scrollPositionInitted.current) {
        scrollToTop();
      } else {
        scrollPositionInitted.current = true;
      }
    }
  }, [activeStep]);

  useEffect(() => {
    if (serviceProfile) {
      initted.current = true;
    }
  }, [serviceProfile]);

  useEffect(() => {
    if (
      siteConfig.id !== 'mi' &&
      authUser &&
      authUser.User &&
      authUser.User.Email === 'demo@movingin.com'
    ) {
      actions.logOutUser();
      actions.pageLoading(true, 'Logging out...');
      window.location.reload();
    } else if (authUser && (!authUser.User || !authUser.User.Token)) {
      setActiveStep(null);
    }
  }, [authUser]);

  useEffect(() => {
    window.addEventListener('beforeunload', warnIfChangesNotSaved);
  }, []);

  useEffect(() => {
    if (
      activeRoute === '/property-setup' &&
      propertyProfile &&
      propertyProfile.ServiceProfile &&
      propertyProfile.ServiceProfile.Header &&
      !propertyProfile.ServiceProfile.Header.ForEditing
    ) {
      loadPropertyProfile();
    }
  }, [activeRoute]);

  function warnIfChangesNotSaved(ev) {
    if (
      changesMade &&
      changesMade.current &&
      activeRoute === '/property-setup'
    ) {
      ev.preventDefault();
      return (ev.returnValue =
        "Your changes haven't been saved. Are you sure you want to continue?");
    } else {
      window.removeEventListener('beforeunload', warnIfChangesNotSaved);
    }
  }

  function loadPropertyProfile() {
    changesMade.current = false;
    serviceProfileLoaded.current = false;

    actions.pageLoading(true, 'Loading property settings...');

    return propertyProfileApi
      .getPropertyProfile(profileKey, activeRoute === '/property-setup')
      .then((_propertyProfile) => {
        setPropertyProfile(_propertyProfile);
        actions.pageLoading(false);
        if (
          _propertyProfile.ServiceProfile &&
          _propertyProfile.ServiceProfile.Header &&
          _propertyProfile.ServiceProfile.Header.Address
        ) {
          dataApi.setCookie(
            'mi-profile-key',
            profileKey + ':' + _propertyProfile.ServiceProfile.Header.Address
          );
        }

        return _propertyProfile;
      });
  }

  function scrollToTop() {
    const element = document.getElementsByClassName('page')[0];
    if (element) {
      dataApi.scrollToElementInPage(element, 0, true);
    }
  }

  function setProviderServiceValue(
    providerService,
    options,
    skipActiveStepCheck
  ) {
    setServiceProfile({
      ...serviceProfile,
      ServiceSectionList: serviceProfile.ServiceSectionList.map(
        (step, stepIndex) =>
          stepIndex === activeStep || skipActiveStepCheck
            ? {
                ...step,
                ProviderServiceList: step.ProviderServiceList.map(
                  (_providerService) =>
                    _providerService.ProviderServiceID ===
                    providerService.ProviderServiceID
                      ? {
                          ..._providerService,
                          ...options
                        }
                      : { ..._providerService }
                )
              }
            : { ...step }
      )
    });
  }

  function setProviderValue(provider, options) {
    setServiceProfile({
      ...serviceProfile,
      ServiceSectionList: serviceProfile.ServiceSectionList.map(
        (step, stepIndex) =>
          stepIndex === activeStep
            ? {
                ...step,
                ProviderList: step.ProviderList.map((_provider) =>
                  _provider.ProviderID === provider.ProviderID
                    ? {
                        ..._provider,
                        ...options
                      }
                    : { ..._provider }
                )
              }
            : { ...step }
      )
    });
  }

  function saveServiceProfile(step, isSubmit) {
    if (saveTimeout.current) {
      clearTimeout(saveTimeout.current);
    }

    propertyProfileApi.savePropertyProfile(
      profileKey,
      {
        ...serviceProfile,
        Header: {
          ...serviceProfile.Header,
          ActiveStep: step
        },
        ServiceSectionList: serviceProfile.ServiceSectionList.map(
          (serviceSection) => {
            return {
              ...serviceSection,
              ProviderList: serviceSection.ProviderList.map((provider) =>
                serviceSection.ProviderServiceList.filter(
                  (providerService) =>
                    serviceSection.ServiceSectionID !== 2 &&
                    providerService.preferredProviderID === provider.ProviderID
                ).length
                  ? { ...provider, IsProviderPreferred: 1 }
                  : {
                      ...provider,
                      IsProviderPreferred:
                        serviceSection.ServiceSectionID === 2
                          ? provider.IsProviderPreferred
                          : null
                    }
              )
            };
          }
        )
      },
      isSubmit
    );
  }

  function restrictNumber(event) {
    switch (event.keyCode) {
      case 8: //Backspace
      case 46: //Delete
        break;
      default:
        if (isNaN(event.key)) {
          event.preventDefault();
        }
        break;
    }
  }

  function formatThousands(value) {
    return value ? Number(value.split(',').join('')).toLocaleString() : null;
  }

  function getPropertyName() {
    if (
      serviceProfile &&
      serviceProfile.Header &&
      serviceProfile.Header.PropertyNameEntered
    ) {
      return serviceProfile.Header.PropertyNameEntered;
    } else if (propertyProfile && propertyProfile.Property) {
      return propertyProfile.Property.PropertyName;
    }
    return '';
  }

  function getUnitCount() {
    if (
      serviceProfile &&
      serviceProfile.Header &&
      serviceProfile.Header.UnitCountEntered
    ) {
      return serviceProfile.Header.UnitCountEntered;
    } else if (propertyProfile && propertyProfile.Property.UnitCount) {
      return propertyProfile.Property.UnitCount;
    }
    return '';
  }

  function getServiceProofRequiredProviderService() {
    let _proofRequiredProviderServiceList = [];
    if (serviceProfile && serviceProfile.ServiceSectionList) {
      serviceProfile.ServiceSectionList.map((serviceSection) => {
        _proofRequiredProviderServiceList =
          _proofRequiredProviderServiceList.concat(
            serviceSection.ProviderServiceList.filter(
              (providerService) => providerService.IsServiceProofRequired
            )
          );
      });
    }
    return _proofRequiredProviderServiceList;
  }

  function updateRoute(newRoute) {
    setActiveRoute(newRoute);

    window.history.pushState({}, '', newRoute + '?property=' + profileKey);
  }

  function logUserIn(email, password) {
    setUserError(null);
    logIn(email, password).then((result) => {
      if (result && result.User && result.User.Token) {
        actions.userLoggedIn(result);
        if (serviceProfile.Header.ActiveStep) {
          setActiveStep(serviceProfile.Header.ActiveStep);
        } else {
          setActiveStep(0);
        }
      } else {
        setUserError(<>Invalid email address or password.</>);
      }
    });
  }

  function userHasAccess() {
    return (
      authUser &&
      authUser.User &&
      ((authUser.PropertyList &&
        authUser.PropertyList.filter(
          (_property) => _property.ProfileKey === profileKey
        ).length !== 0) ||
        authUser.User.IsAdmin)
    );
  }

  function userIsReadOnly() {
    return (
      authUser &&
      authUser.User &&
      authUser.User.Token &&
      authUser.PropertyList &&
      !authUser.PropertyList.filter(
        (_property) =>
          _property.ProfileKey === profileKey && _property.UserLevelID >= 9
      ).length &&
      (!propertyProfile.Property ||
        !propertyProfile.Property.UserHasAccess ||
        authUser.PropertyList.filter(
          (_property) =>
            _property.ProfileKey === profileKey && _property.UserLevelID < 9
        ).length)
    );
  }

  function goToResidentDashboard() {
    if (
      authUser &&
      authUser.User &&
      authUser.PropertyList &&
      authUser.PropertyList.filter(
        (property) =>
          property.PropertyID === serviceProfile.Header.PropertyID &&
          property.UnitCount > 0
      ).length
    ) {
      setShowPropertySelect(activeRoute + '?property=' + profileKey);
    } else {
      dataApi.setSessionStorage(
        'mi-last-route',
        activeRoute + '?property=' + profileKey
      );

      setSelectedResident(
        actions.pageLoading,
        actions.serviceAddressUpdated,
        null,
        null,
        propertyProfile.ServiceProfile.SampleResident.Address,
        propertyProfile.ServiceProfile.SampleResident.Secondary,
        propertyProfile.ServiceProfile.SampleResident.City,
        propertyProfile.ServiceProfile.SampleResident.State,
        propertyProfile.ServiceProfile.SampleResident.Zip,
        propertyProfile.ServiceProfile.SampleResident.UniqueAddressId,
        null,
        null,
        null,
        null,
        null,
        propertyProfile.ServiceProfile.SampleResident.ResidentID,
        propertyProfile.ServiceProfile.SampleResident.FirstName,
        propertyProfile.ServiceProfile.SampleResident.LastName,
        propertyProfile.ServiceProfile.SampleResident.Email,
        propertyProfile.ServiceProfile.SampleResident.Phone,
        propertyProfile.ServiceProfile.SampleResident.DateLeaseStart,
        propertyProfile.ServiceProfile.SampleResident.TenantId,
        actions.clearResidentOnboarding
      );

      // let sampleAddress = {
      //   Address: propertyProfile.ServiceProfile.Header.Address,
      //   Secondary: propertyProfile.ServiceProfile.Header.Secondary,
      //   City: propertyProfile.ServiceProfile.Header.City,
      //   State: propertyProfile.ServiceProfile.Header.State,
      //   Zip: propertyProfile.ServiceProfile.Header.Zip,
      //   ServiceAddressSourceID: 2
      // };

      // if (propertyProfile.ServiceProfile.SampleResident) {
      //   sampleAddress.Address =
      //     propertyProfile.ServiceProfile.SampleResident.Address;
      //   sampleAddress.Secondary =
      //     propertyProfile.ServiceProfile.SampleResident.Secondary;
      //   sampleAddress.City = propertyProfile.ServiceProfile.SampleResident.City;
      //   sampleAddress.State =
      //     propertyProfile.ServiceProfile.SampleResident.State;
      //   sampleAddress.Zip = propertyProfile.ServiceProfile.SampleResident.Zip;
      //   sampleAddress.ServiceAddressSourceID = 10;
      //   sampleAddress.UniqueAddressId =
      //     propertyProfile.ServiceProfile.SampleResident.UniqueAddressId;

      //   sampleAddress.Previous_Address =
      //     propertyProfile.ServiceProfile.SampleResident.Previous_Address;

      //   sampleAddress.Previous_Secondary =
      //     propertyProfile.ServiceProfile.SampleResident.Previous_Secondary;

      //   sampleAddress.Previous_City =
      //     propertyProfile.ServiceProfile.SampleResident.Previous_City;
      //   sampleAddress.Previous_State =
      //     propertyProfile.ServiceProfile.SampleResident.Previous_State;
      //   sampleAddress.Previous_Zip =
      //     propertyProfile.ServiceProfile.SampleResident.Previous_Zip;
      // }

      // dataApi
      //   .saveServiceAddress(
      //     sampleAddress.Address,
      //     sampleAddress.Secondary,
      //     sampleAddress.City,
      //     sampleAddress.State,
      //     sampleAddress.Zip,
      //     sampleAddress.ServiceAddressSourceID,
      //     sampleAddress.UniqueAddressId,
      //     sampleAddress.Previous_Address,
      //     sampleAddress.Previous_Secondary,
      //     sampleAddress.Previous_City,
      //     sampleAddress.Previous_State,
      //     sampleAddress.Previous_Zip
      //   )
      //   .then((result) => {
      //     //TODO COPY OVER RESIDENT INFORMATION BEFORE CONTINUE
      //     if (result && result.Address) {
      //       let _serviceAddress = { ...result };

      //       if (propertyProfile.ServiceProfile.SampleResident) {
      //         _serviceAddress = {
      //           ..._serviceAddress,
      //           Address: {
      //             ..._serviceAddress.Address,
      //             ResidentID:
      //               propertyProfile.ServiceProfile.SampleResident.ResidentID,
      //             FirstName:
      //               propertyProfile.ServiceProfile.SampleResident.FirstName,
      //             LastName:
      //               propertyProfile.ServiceProfile.SampleResident.LastName,
      //             DefaultStartDate:
      //               propertyProfile.ServiceProfile.SampleResident
      //                 .DateLeaseStart,
      //             Email: propertyProfile.ServiceProfile.SampleResident.Email,
      //             Phone: propertyProfile.ServiceProfile.SampleResident.Phone,
      //             Previous_Address:
      //               propertyProfile.ServiceProfile.SampleResident
      //                 .Previous_Address,
      //             Previous_Secondary:
      //               propertyProfile.ServiceProfile.SampleResident
      //                 .Previous_Secondary,
      //             Previous_City:
      //               propertyProfile.ServiceProfile.SampleResident.Previous_City,
      //             Previous_State:
      //               propertyProfile.ServiceProfile.SampleResident
      //                 .Previous_State,
      //             Previous_Zip:
      //               propertyProfile.ServiceProfile.SampleResident.Previous_Zip
      //           }
      //         };
      //       }

      //       actions.serviceAddressUpdated(_serviceAddress);
      //     }

      //     actions.pageLoading(false);

      //     navigate('/dashboard');
      //   });
    }
  }

  function isOnboardingRoute() {
    return (
      activeRoute === '/property-onboarding' ||
      activeRoute === '/property-applicants' ||
      activeRoute === '/property-notice' ||
      activeRoute === '/property-events'
    );
  }

  return (
    <div
      className={
        'page-container service-profile-container' +
        (activeStep !== null && !propertyInfoEditMode ? ' started' : '') +
        (getPropertyName() && !propertyInfoEditMode ? ' with-property' : '') +
        (isCompleted ? ' complete ' : '') +
        (isOnboardingRoute()
          ? ' resident-onboarding '
          : ' ' + activeRoute.split('/').join('')) +
        (' ' + activeRoute.split('/').join(''))
      }>
      {' '}
      {propertyProfile &&
      propertyProfile.Property &&
      serviceProfile &&
      serviceProfile.ServiceSectionList &&
      serviceProfile.Header ? (
        <>
          <div className="property-image">
            {/* {getPropertyName() && !propertyInfoEditMode ? (
              <Dropzone
                getUploadParams={() => {
                  return {
                    url:
                      authUser.links.apiUrl +
                      'FileUpload/ImportOffers?updatingUser=' +
                      authUser.email
                  };
                }}
                onChangeStatus={(upload, status) => {
                  console.log(status);
                  if (status == 'done') {
                    setProgressMessage(null);
                    const fileUploadResponse = JSON.parse(upload.xhr.response);
                    upload.remove();
                    if (fileUploadResponse.ImportedOfferCount) {
                      setSuccess(fileUploadResponse);
                      if (offersImported) {
                        offersImported();
                      }
                    } else {
                      setError(fileUploadResponse.ResultMessage);
                    }
                  } else if (status === 'uploading') {
                    setError('');
                    setSuccess(null);
                    setProgressMessage('Uploading file...');
                  } else if (status === 'aborted') {
                    //toast(`${meta.name}, upload failed...`);
                  }
                }}
                maxFiles={1}
                accept="image/*"
                multiple={false}
                canCancel={false}
                inputContent={
                  <>
                    Upload a photo of {getPropertyName()} <CloudUploadIcon />
                  </>
                }
              />
            ) : null} */}
          </div>

          <div className="page">
            <div className="inner">
              {getPropertyName() && !propertyInfoEditMode ? (
                <PropertyHeader
                  property={serviceProfile.Header}
                  isCompleted={isCompleted}
                  activeRoute={activeRoute}
                  updateRoute={updateRoute}
                  setShowRestoreDefaults={setShowRestoreDefaults}
                  getPropertyName={getPropertyName}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  siteConfig={siteConfig}
                  setShowPropertySelect={setShowPropertySelect}
                  profileKey={profileKey}
                  setJustCompleted={setJustCompleted}
                  goToResidentDashboard={goToResidentDashboard}
                  userHasAccess={userHasAccess}
                  leftTab={leftTab}
                  rightTab={rightTab}
                  tabCounts={tabCounts}
                  serviceProfile={serviceProfile}
                  userIsReadOnly={userIsReadOnly}
                  authUser={authUser}
                />
              ) : null}

              {activeRoute === '/property-setup' &&
              (activeStep !== null || isCompleted) &&
              !userIsReadOnly() &&
              userHasAccess() &&
              siteConfig.id === 'mi' ? (
                <Stack sx={{ width: '100%' }} spacing={4}>
                  <Stepper
                    alternativeLabel
                    activeStep={
                      alreadyCompleted
                        ? serviceProfile.ServiceSectionList.length
                        : activeStep
                    }
                    className={alreadyCompleted ? 'completed' : ''}
                    connector={<QontoConnector />}>
                    {serviceProfile.ServiceSectionList.map(
                      (step, stepIndex) => (
                        <Step
                          key={step.ServiceSectionID}
                          onClick={() => {
                            setJustCompleted(false);
                            setIsCompleted(false);
                            setActiveStep(stepIndex);
                          }}
                          className={
                            activeStep === stepIndex && !isCompleted
                              ? 'active'
                              : ''
                          }>
                          <StepLabel StepIconComponent={QontoStepIcon}>
                            {step.ServiceSectionName}
                          </StepLabel>
                        </Step>
                      )
                    )}
                  </Stepper>
                </Stack>
              ) : null}

              {activeRoute !== '/property-users' && siteConfig.id !== 'mi' ? (
                <SCPropertyDashboard
                  profileKey={profileKey}
                  propertyProfile={propertyProfile}
                  loadPropertyProfile={loadPropertyProfile}
                />
              ) : userHasAccess() && isOnboardingRoute() ? (
                <OnboardingDashboard
                  profileKey={profileKey}
                  serviceProfile={serviceProfile}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  leftTab={leftTab}
                  rightTab={rightTab}
                  activeRoute={activeRoute}
                  setTabCounts={setTabCounts}
                />
              ) : userHasAccess() && activeRoute === '/property-calendars' ? (
                <PropertyCalendar profileKey={profileKey} />
              ) : userHasAccess() && activeRoute === '/property-users' ? (
                <PropertyUsers
                  serviceProfile={serviceProfile}
                  profileKey={profileKey}
                />
              ) : activeRoute === '/property-contact' ? (
                <PropertyContact profileKey={profileKey} />
              ) : userHasAccess() && activeRoute === '/property-vehicles' ? (
                <PropertyVehicles
                  profileKey={profileKey}
                  searchString={searchString}
                  setSearchString={setSearchString}
                />
              ) : userHasAccess() && activeRoute === '/property-pets' ? (
                <PropertyPets
                  profileKey={profileKey}
                  searchString={searchString}
                  setSearchString={setSearchString}
                />
              ) : justCompleted ? (
                <div className="property-profile-complete">
                  {!alreadyCompleted ? <CanvasConfetti /> : null}
                  <p>
                    Your changes to the Property Profile have been saved. If you
                    would like to make additional edits, just click one of the
                    sections above to get started. If you are done, you can
                    choose from one of the options below.
                  </p>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      goToResidentDashboard();
                    }}
                    style={{ marginBottom: '10px' }}>
                    Go to Resident Dashboard to View Change
                  </Button>
                  <br />
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => updateRoute('/property-events')}
                    style={{ marginBottom: '10px' }}>
                    Return to {siteConfig.brand} Dashboard
                  </Button>
                </div>
              ) : userIsReadOnly() ? (
                <Alert severity="error">
                  You do not have Account Owner access to this property.
                </Alert>
              ) : isCompleted && userHasAccess() ? (
                <div className="property-profile-complete">
                  <p>
                    You can edit your existing Property Profile by clicking the
                    section of interest above.
                  </p>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => updateRoute('/property-events')}
                    style={{ marginBottom: '10px' }}>
                    OR, return to {siteConfig.brand} Dashboard{' '}
                  </Button>
                </div>
              ) : activeStep !== null && !propertyInfoEditMode ? (
                <>
                  {serviceProfile.ServiceSectionList[activeStep] &&
                  serviceProfile.ServiceSectionList[activeStep]
                    .ProviderServiceList ? (
                    serviceProfile.ServiceSectionList[activeStep]
                      .ServiceSectionID === 1 ? (
                      <div className="form">
                        <StepServicesRequired
                          serviceProfile={serviceProfile}
                          activeStep={activeStep}
                          setProviderServiceValue={setProviderServiceValue}
                          alreadyCompleted={alreadyCompleted}
                          profileKey={profileKey}
                          setServiceProfile={setServiceProfile}
                        />
                      </div>
                    ) : serviceProfile.ServiceSectionList[activeStep]
                        .ServiceSectionID === 2 ? (
                      <div className="form">
                        <StepInternetOptions
                          serviceProfile={serviceProfile}
                          activeStep={activeStep}
                          setProviderServiceValue={setProviderServiceValue}
                          profileKey={profileKey}
                          setServiceProfile={setServiceProfile}
                        />
                      </div>
                    ) : serviceProfile.ServiceSectionList[activeStep]
                        .ServiceSectionID === 18 ? (
                      <div className="form">
                        <div className="section">
                          <h3>
                            Property Partners - Based on your settings below,
                            this is what your residents will see:
                          </h3>

                          <div className="property-partner-option-list preview">
                            <h4>Property Level Resident Services</h4>

                            {serviceProfile.Header.DisplayResidentPortalLink ? (
                              <div
                                className={
                                  'property-partner-option  with-description'
                                }>
                                <div>
                                  <div className="provider-logo-container">
                                    <Button
                                      href={
                                        serviceProfile.Header.ResidentPortalUrl
                                      }
                                      target="_blank">
                                      <span className="property-name">
                                        {getPropertyName()}
                                      </span>

                                      <div className="description">
                                        <strong>Resident Portal</strong>
                                        <br />
                                        Contact the Property
                                        <br />
                                        Pay Rent
                                        <br />
                                        Request Service
                                      </div>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {serviceProfile.ServiceSectionList[
                              activeStep
                            ].ProviderServiceList.filter(
                              (providerService) =>
                                providerService.ProviderServiceCategoryID === 11
                            ).map((providerService, providerServiceIndex) =>
                              serviceProfile.ServiceSectionList[
                                activeStep
                              ].ProviderList.filter(
                                (provider) =>
                                  provider.ProviderServiceID ===
                                    providerService.ProviderServiceID &&
                                  provider.IsProviderAvailable
                              ).map((provider) => (
                                <div
                                  className={
                                    'property-partner-option' +
                                    (provider.Description
                                      ? ' with-description'
                                      : '')
                                  }>
                                  <div>
                                    <div className="provider-logo-container">
                                      <Button
                                        href={provider.WebsiteUrl}
                                        target="_blank">
                                        {provider.LogoFile ? (
                                          <img
                                            src={provider.LogoFile}
                                            className="provider-logo"
                                          />
                                        ) : (
                                          <span>{provider.ProviderName}</span>
                                        )}
                                        {provider.Description ? (
                                          <div
                                            className="description"
                                            dangerouslySetInnerHTML={{
                                              __html: provider.Description
                                            }}></div>
                                        ) : null}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>

                          <Alert severity="info">
                            Does your community partner with any of the
                            following service providers? If yes, click each
                            provider below and we'll add them to the bottom of
                            the resident dashboard.
                          </Alert>

                          {serviceProfile.Header.ResidentPortalUrl ? (
                            <div className="property-partner-option-list">
                              <h4>Resident Portal</h4>
                              <div
                                className={
                                  'property-partner-option  with-description'
                                }>
                                <div>
                                  <div className="provider-logo-container">
                                    <Button
                                      className={
                                        serviceProfile.Header
                                          .DisplayResidentPortalLink
                                          ? 'selected'
                                          : ''
                                      }
                                      onClick={(event) =>
                                        setServiceProfile({
                                          ...serviceProfile,
                                          Header: {
                                            ...serviceProfile.Header,
                                            DisplayResidentPortalLink:
                                              !serviceProfile.Header
                                                .DisplayResidentPortalLink
                                          }
                                        })
                                      }>
                                      <span className="property-name">
                                        {getPropertyName()}
                                      </span>

                                      <div className="description">
                                        <strong>Resident Portal</strong>
                                        <br />
                                        Contact the Property
                                        <br />
                                        Pay Rent
                                        <br />
                                        Request Service
                                      </div>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {serviceProfile.ServiceSectionList[
                            activeStep
                          ].ProviderServiceList.filter(
                            (providerService) =>
                              providerService.ProviderServiceCategoryID === 11
                          ).map((providerService, providerServiceIndex) => (
                            <div className="property-partner-option-list">
                              <h4>{providerService.ProviderServiceName}</h4>
                              {serviceProfile.ServiceSectionList[
                                activeStep
                              ].ProviderList.filter(
                                (provider) =>
                                  provider.ProviderServiceID ===
                                  providerService.ProviderServiceID
                              ).map((provider) => (
                                <div
                                  className={
                                    'property-partner-option' +
                                    (provider.Description
                                      ? ' with-description'
                                      : '')
                                  }>
                                  <div>
                                    <div className="provider-logo-container">
                                      <Button
                                        className={
                                          provider.IsProviderAvailable
                                            ? 'selected'
                                            : ''
                                        }
                                        onClick={(event) =>
                                          setProviderValue(provider, {
                                            IsProviderAvailable:
                                              !provider.IsProviderAvailable
                                          })
                                        }>
                                        {provider.LogoFile ? (
                                          <img
                                            src={provider.LogoFile}
                                            className="provider-logo"
                                          />
                                        ) : (
                                          <span>{provider.ProviderName}</span>
                                        )}
                                        {provider.Description ? (
                                          <div
                                            className="description"
                                            dangerouslySetInnerHTML={{
                                              __html: provider.Description
                                            }}></div>
                                        ) : null}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}

                          <OtherPartnerField
                            field={'PropertyPartnerOther1'}
                            serviceProfile={serviceProfile}
                            setServiceProfile={setServiceProfile}
                          />

                          {serviceProfile.Header.PropertyPartnerOther1 ? (
                            <OtherPartnerField
                              field={'PropertyPartnerOther2'}
                              serviceProfile={serviceProfile}
                              setServiceProfile={setServiceProfile}
                            />
                          ) : null}

                          {serviceProfile.Header.PropertyPartnerOther2 ? (
                            <OtherPartnerField
                              field={'PropertyPartnerOther3'}
                              serviceProfile={serviceProfile}
                              setServiceProfile={setServiceProfile}
                            />
                          ) : null}

                          {serviceProfile.Header.PropertyPartnerOther3 ? (
                            <OtherPartnerField
                              field={'PropertyPartnerOther4'}
                              serviceProfile={serviceProfile}
                              setServiceProfile={setServiceProfile}
                            />
                          ) : null}

                          {serviceProfile.Header.PropertyPartnerOther4 ? (
                            <OtherPartnerField
                              field={'PropertyPartnerOther5'}
                              serviceProfile={serviceProfile}
                              setServiceProfile={setServiceProfile}
                            />
                          ) : null}
                        </div>
                      </div>
                    ) : serviceProfile.ServiceSectionList[activeStep]
                        .ServiceSectionID === 4 ? (
                      serviceProfile.ServiceSectionList[
                        activeStep
                      ].ProviderServiceList.filter(
                        (providerService) =>
                          providerService.ProviderServiceID === 37
                      ).map((providerService, providerServiceIndex) => (
                        <div className="form">
                          <StepRentersInsurance
                            serviceProfile={serviceProfile}
                            activeStep={activeStep}
                            setProviderServiceValue={setProviderServiceValue}
                            profileKey={profileKey}
                            setServiceProfile={setServiceProfile}
                            providerService={providerService}
                            setProviderValue={setProviderValue}
                            alreadyCompleted={alreadyCompleted}
                          />
                        </div>
                      ))
                    ) : // : serviceProfile.ServiceSectionList[activeStep]
                    //     .ServiceSectionID === 5 ? (
                    //   serviceProfile.ServiceSectionList[
                    //     activeStep
                    //   ].ProviderServiceList.filter(
                    //     (providerService) =>
                    //       providerService.ProviderServiceID === 20
                    //   ).map((providerService, providerServiceIndex) => (
                    //     <StorageParkingOptions
                    //       setProviderServiceValue={setProviderServiceValue}
                    //       providerService={providerService}
                    //     />
                    //   ))
                    // )
                    serviceProfile.ServiceSectionList[activeStep]
                        .ServiceSectionID === -1 ? (
                      <div className="form">
                        <StepSchools
                          serviceProfile={serviceProfile}
                          activeStep={activeStep}
                          setProviderServiceValue={setProviderServiceValue}
                          profileKey={profileKey}
                          setServiceProfile={setServiceProfile}
                        />
                      </div>
                    ) : serviceProfile.ServiceSectionList[activeStep]
                        .ServiceSectionID === -2 ? (
                      <RequiredTasks
                        serviceProfile={serviceProfile}
                        setServiceProfile={setServiceProfile}
                        authUser={authUser}
                        siteConfig={siteConfig}
                      />
                    ) : serviceProfile.ServiceSectionList[activeStep]
                        .ServiceSectionID === -3 ? (
                      <>
                        <div className="form">
                          <div className="section overview">
                            <h3>Property Overview</h3>

                            <div className="form-content property-info">
                              <PropertyInfoEdit
                                serviceProfile={serviceProfile}
                                setServiceProfile={setServiceProfile}
                                stateList={stateList}
                                siteConfig={siteConfig}
                              />

                              <p>How can residents contact the property?</p>

                              <TextField
                                value={
                                  serviceProfile.Header.PropertyCSEmailEntered
                                }
                                label="Property Email Address for Resident Questions"
                                onChange={(event) =>
                                  setServiceProfile({
                                    ...serviceProfile,
                                    Header: {
                                      ...serviceProfile.Header,
                                      PropertyCSEmailEntered: event.target.value
                                    }
                                  })
                                }
                                fullWidth
                                type="email"
                              />

                              <InputMask
                                mask="999-999-9999"
                                maskChar="_"
                                value={
                                  serviceProfile.Header.PropertyCSPhoneEntered
                                }
                                onChange={(event) =>
                                  setServiceProfile({
                                    ...serviceProfile,
                                    Header: {
                                      ...serviceProfile.Header,
                                      PropertyCSPhoneEntered: event.target.value
                                    }
                                  })
                                }>
                                {() => (
                                  <TextField
                                    variant="outlined"
                                    id="phone"
                                    name="phone"
                                    label={
                                      'Property Phone Number for Resident Questions'
                                    }
                                    type="tel"
                                    fullWidth
                                  />
                                )}
                              </InputMask>

                              <TextField
                                fullWidth
                                label={'Resident Portal URL (website address)'}
                                value={serviceProfile.Header.ResidentPortalUrl}
                                onChange={(event) =>
                                  setServiceProfile({
                                    ...serviceProfile,
                                    Header: {
                                      ...serviceProfile.Header,
                                      ResidentPortalUrl: event.target.value
                                    }
                                  })
                                }
                                onBlur={() => {
                                  if (
                                    serviceProfile.Header.ResidentPortalUrl &&
                                    serviceProfile.Header.ResidentPortalUrl.indexOf(
                                      'http'
                                    ) === -1
                                  ) {
                                    setServiceProfile({
                                      ...serviceProfile,
                                      Header: {
                                        ...serviceProfile.Header,
                                        ResidentPortalUrl:
                                          'https://' +
                                          serviceProfile.Header
                                            .ResidentPortalUrl
                                      }
                                    });
                                  }
                                }}
                                placeholder={'https://'}
                              />

                              {serviceProfile.Header.ResidentPortalUrl ? (
                                <div className="resident-portal-test">
                                  <a
                                    className="link"
                                    href={
                                      serviceProfile.Header.ResidentPortalUrl
                                    }
                                    target="_blank">
                                    REQUIRED: Test the Resident Portal link by
                                    clicking here to make sure the above link
                                    works and goes where you want it to.
                                  </a>
                                </div>
                              ) : null}

                              {serviceProfile.Header.ResidentPortalUrl ? (
                                <FormControl>
                                  <RadioGroup
                                    row
                                    name="service-available"
                                    value={
                                      serviceProfile.Header
                                        .DisplayResidentPortalLink
                                    }
                                    onChange={(event) => {
                                      setServiceProfile({
                                        ...serviceProfile,
                                        Header: {
                                          ...serviceProfile.Header,
                                          DisplayResidentPortalLink:
                                            event.target.value === 'true'
                                        }
                                      });
                                    }}>
                                    <p>
                                      Would you like to display a link to your
                                      resident portal on the resident dashboard?
                                    </p>
                                    <br />
                                    <div style={{ marginLeft: '50px' }}>
                                      <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label={'Yes'}
                                      />
                                      <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label={'No'}
                                      />
                                    </div>
                                  </RadioGroup>
                                </FormControl>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        {(serviceProfile &&
                          serviceProfile.Header &&
                          serviceProfile.Header.UseResidentDashboard) ||
                        alreadyCompleted
                          ? [
                              serviceProfile.ServiceSectionList[activeStep]
                                .ProviderServiceList[0]
                            ].map((providerService) => (
                              <StorageParkingOptions
                                providerService={providerService}
                                setProviderServiceValue={
                                  setProviderServiceValue
                                }
                              />
                            ))
                          : null}
                      </>
                    ) : null
                  ) : null}

                  <div className="buttons">
                    {!alreadyCompleted ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setActiveStep(activeStep - 1);
                        }}
                        disabled={!activeStep}>
                        <ChevronLeftIcon /> Back
                      </Button>
                    ) : null}

                    {alreadyCompleted ? (
                      <Button
                        className="right"
                        onClick={() => {
                          loadPropertyProfile();
                        }}>
                        Cancel
                      </Button>
                    ) : null}

                    <Button
                      variant="contained"
                      className="right"
                      color="secondary"
                      disabled={
                        activeStep === serviceProfile.ServiceSectionList.length
                      }
                      onClick={() => {
                        if (
                          activeStep ===
                            serviceProfile.ServiceSectionList.length - 1 ||
                          alreadyCompleted
                        ) {
                          setIsCompleted(true);
                          setJustCompleted(true);
                          scrollToTop();
                        } else {
                          setActiveStep(activeStep + 1);
                        }

                        saveServiceProfile(
                          activeStep + 1,
                          activeStep ===
                            serviceProfile.ServiceSectionList.length - 1
                        );

                        changesMade.current = false;
                      }}>
                      {activeStep ===
                      serviceProfile.ServiceSectionList.length ? (
                        'All Done!'
                      ) : alreadyCompleted ? (
                        'Save Changes'
                      ) : activeStep ===
                        serviceProfile.ServiceSectionList.length - 1 ? (
                        'Submit'
                      ) : (
                        <>
                          Save & Continue <ChevronRightIcon />{' '}
                        </>
                      )}
                    </Button>
                  </div>
                </>
              ) : propertyProfile.Property &&
                getPropertyName() &&
                !propertyInfoEditMode ? (
                <>
                  <div className="property-profile-welcome">
                    <p className="title">
                      Welcome to{' '}
                      <img
                        className="logo"
                        alt={siteConfig.brand}
                        src={withPrefix(siteConfig.logo)}
                      />
                    </p>

                    <img
                      className="screenshot"
                      src={withPrefix(
                        '/images/property-profile/screenshot-dashboard.jpg'
                      )}
                    />
                    <p>
                      Completing your property profile should only take a few
                      minutes and will ensure that we have accurate and
                      up-to-date information about your property.
                    </p>
                    <p>
                      Should you have any questions or need further assistance,
                      please don't hesitate to reach out to our support team at
                      support@movingin.com.
                    </p>
                    <p>
                      Thank you for choosing us as your partner. We look forward
                      to enhancing the moving in experience with our services.
                    </p>

                    {!authUser || !authUser.User || !authUser.User.Token ? (
                      <>
                        <p>
                          {serviceProfile &&
                          serviceProfile.Header &&
                          serviceProfile.Header.HasLogin ? (
                            <strong>
                              To get started, please log in with the password
                              you previously created using the form below.
                            </strong>
                          ) : (
                            <strong>
                              Before we get started, we'll need you to set up an
                              account. Please create a password and add your
                              contact info below.
                            </strong>
                          )}
                        </p>

                        <div className="form">
                          {userError ? (
                            <Alert
                              severity="error"
                              style={{ marginBottom: '20px' }}>
                              {userError}
                            </Alert>
                          ) : null}

                          <TextField
                            value={serviceProfile.Header.UpdaterEmail}
                            required
                            disabled={
                              serviceProfile.Header.UpdaterEmail &&
                              serviceProfile.Header.UpdaterEmail ===
                                serviceProfile.Header.PropertyEmail &&
                              !serviceProfile.Header.HasLogin
                            }
                            label="Your Email"
                            onChange={(event) =>
                              setServiceProfile({
                                ...serviceProfile,
                                Header: {
                                  ...serviceProfile.Header,
                                  UpdaterEmail: event.target.value
                                }
                              })
                            }
                            type="email"
                            fullWidth
                          />

                          <TextField
                            value={serviceProfile.Header.UpdaterPassword}
                            label={
                              serviceProfile &&
                              serviceProfile.Header &&
                              serviceProfile.Header.HasLogin
                                ? 'Your Password'
                                : 'Create a Password'
                            }
                            type={
                              serviceProfile.Header.ShowPassword
                                ? 'text'
                                : 'password'
                            }
                            required
                            onChange={(event) =>
                              setServiceProfile({
                                ...serviceProfile,
                                Header: {
                                  ...serviceProfile.Header,
                                  UpdaterPassword: event.target.value
                                }
                              })
                            }
                            helperText={
                              serviceProfile &&
                              serviceProfile.Header &&
                              serviceProfile.Header.HasLogin ? (
                                <>
                                  <a
                                    className="link"
                                    style={{ float: 'right' }}
                                    onClick={() => setShowPasswordHelp(true)}>
                                    Forgot password?
                                  </a>

                                  <a
                                    className="link"
                                    onClick={() =>
                                      setServiceProfile({
                                        ...serviceProfile,
                                        Header: {
                                          ...serviceProfile.Header,
                                          HasLogin: false
                                        }
                                      })
                                    }>
                                    Don't have an account?
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    className="link"
                                    style={{ float: 'right' }}
                                    onClick={() => setShowLoginForm(true)}>
                                    Already registered?
                                  </a>
                                  {PasswordPolicy}
                                </>
                              )
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setServiceProfile({
                                        ...serviceProfile,
                                        Header: {
                                          ...serviceProfile.Header,
                                          ShowPassword:
                                            !serviceProfile.Header.ShowPassword
                                        }
                                      })
                                    }>
                                    {serviceProfile.Header.ShowPassword ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            fullWidth
                          />

                          {serviceProfile &&
                          serviceProfile.Header &&
                          !serviceProfile.Header.HasLogin ? (
                            <>
                              <TextField
                                value={serviceProfile.Header.UpdaterFirstName}
                                label="Your First Name"
                                required
                                onChange={(event) =>
                                  setServiceProfile({
                                    ...serviceProfile,
                                    Header: {
                                      ...serviceProfile.Header,
                                      UpdaterFirstName: event.target.value
                                    }
                                  })
                                }
                                fullWidth
                              />

                              <TextField
                                value={serviceProfile.Header.UpdaterLastName}
                                label="Your Last Name"
                                required
                                onChange={(event) =>
                                  setServiceProfile({
                                    ...serviceProfile,
                                    Header: {
                                      ...serviceProfile.Header,
                                      UpdaterLastName: event.target.value
                                    }
                                  })
                                }
                                fullWidth
                              />

                              <TextField
                                value={serviceProfile.Header.UpdaterTitle}
                                label="Title"
                                onChange={(event) =>
                                  setServiceProfile({
                                    ...serviceProfile,
                                    Header: {
                                      ...serviceProfile.Header,
                                      UpdaterTitle: event.target.value
                                    }
                                  })
                                }
                                fullWidth
                              />

                              <InputMask
                                mask="999-999-9999"
                                maskChar="_"
                                value={serviceProfile.Header.UpdaterPhone}
                                onChange={(event) =>
                                  setServiceProfile({
                                    ...serviceProfile,
                                    Header: {
                                      ...serviceProfile.Header,
                                      UpdaterPhone: event.target.value
                                    }
                                  })
                                }>
                                {() => (
                                  <TextField
                                    variant="outlined"
                                    id="phone"
                                    name="phone"
                                    label={'Your Phone Number'}
                                    type="tel"
                                    fullWidth
                                  />
                                )}
                              </InputMask>

                              <FormControl>
                                <RadioGroup
                                  row
                                  name="is-onsite-contact"
                                  value={
                                    serviceProfile.Header.UpdaterIsOnsiteContact
                                  }
                                  onChange={(event) => {
                                    setServiceProfile({
                                      ...serviceProfile,
                                      Header: {
                                        ...serviceProfile.Header,
                                        UpdaterIsOnsiteContact:
                                          event.target.value === 'true',
                                        OnsiteContactFirstName:
                                          event.target.value === 'true'
                                            ? serviceProfile.Header
                                                .UpdaterFirstName
                                            : serviceProfile.Header
                                                .OnsiteContactFirstName,
                                        OnsiteContactLastName:
                                          event.target.value === 'true'
                                            ? serviceProfile.Header
                                                .UpdaterLastName
                                            : serviceProfile.Header
                                                .OnsiteContactLastName,
                                        OnsiteContactTitle:
                                          event.target.value === 'true'
                                            ? serviceProfile.Header.UpdaterTitle
                                            : serviceProfile.Header
                                                .OnsiteContactTitle,
                                        OnsiteContactEmail:
                                          event.target.value === 'true'
                                            ? serviceProfile.Header.UpdaterEmail
                                            : serviceProfile.Header
                                                .OnsiteContactEmail,
                                        OnsiteContactPhone:
                                          event.target.value === 'true'
                                            ? serviceProfile.Header.UpdaterPhone
                                            : serviceProfile.Header
                                                .OnsiteContactPhone
                                      }
                                    });
                                  }}>
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label={'Yes'}
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label={'No'}
                                  />
                                  <p style={{ paddingTop: '12px' }}>
                                    Are you the onsite contact for{' '}
                                    {siteConfig.brand}?
                                  </p>
                                </RadioGroup>
                              </FormControl>
                            </>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <div className="form"></div>
                    )}
                  </div>
                  <div className="buttons">
                    <Button
                      style={{ float: 'right' }}
                      variant="contained"
                      color="secondary"
                      disabled={
                        !userHasAccess()
                          ? !serviceProfile ||
                            !serviceProfile.Header ||
                            !serviceProfile.Header.UpdaterEmail ||
                            !serviceProfile.Header.UpdaterPassword ||
                            !validatePassword(
                              serviceProfile.Header.UpdaterPassword
                            ) ||
                            (!serviceProfile.Header.HasLogin &&
                              (!serviceProfile.Header.UpdaterFirstName ||
                                !serviceProfile.Header.UpdaterLastName))
                          : false
                      }
                      onClick={() => {
                        if (userHasAccess()) {
                          if (serviceProfile.Header.ActiveStep) {
                            setActiveStep(serviceProfile.Header.ActiveStep);
                          } else {
                            setActiveStep(0);
                          }
                        } else if (serviceProfile.Header.HasLogin) {
                          logUserIn(
                            serviceProfile.Header.UpdaterEmail,
                            serviceProfile.Header.UpdaterPassword
                          );
                        } else {
                          registerUser(
                            serviceProfile.Header.UpdaterEmail,
                            serviceProfile.Header.UpdaterFirstName,
                            serviceProfile.Header.UpdaterLastName,
                            serviceProfile.Header.UpdaterPassword,
                            profileKey
                          ).then((result) => {
                            if (result.UserID) {
                              logUserIn(
                                serviceProfile.Header.UpdaterEmail,
                                serviceProfile.Header.UpdaterPassword
                              );
                            } else {
                              setUserError(
                                <>
                                  A user with the email address you entered
                                  already exists. Please{' '}
                                  <a
                                    className="link"
                                    onClick={() => {
                                      setServiceProfile({
                                        ...serviceProfile,
                                        Header: {
                                          ...serviceProfile.Header,
                                          HasLogin: true
                                        }
                                      });
                                      setUserError(null);
                                    }}>
                                    log in
                                  </a>{' '}
                                  to continue.
                                </>
                              );
                            }
                          });
                        }
                      }}>
                      Continue to Property Setup
                      <ChevronRightIcon />
                    </Button>
                  </div>
                </>
              ) : (
                <div className="property-profile-header-info">
                  {propertyInfoEditMode ? (
                    <h2>Tell us more about this property...</h2>
                  ) : (
                    <h2>
                      Before we get started, we'll need you to set up an
                      account. Please create a password and add your contact
                      info below.
                    </h2>
                  )}

                  <PropertyInfoEdit
                    serviceProfile={serviceProfile}
                    setServiceProfile={setServiceProfile}
                  />

                  <div className="button">
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={!serviceProfile.Header.PropertyNameEntered}
                      onClick={() => {
                        setServiceProfile({
                          ...serviceProfile,
                          Header: {
                            ...serviceProfile.Header,
                            ...serviceProfile.Header
                          }
                        });
                        setPropertyInfoEditMode(false);
                      }}>
                      Continue <ChevronRightIcon />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
      {showRestoreDefaults ? (
        <PropertyProfileDefaults
          confirmFunc={() => {
            actions.pageLoading(
              true,
              showRestoreDefaults === 'clear'
                ? 'Clearing form...'
                : 'Restoring defaults...'
            );
            propertyProfileApi
              .restoreDefaults(profileKey, showRestoreDefaults)
              .then((result) => {
                setIsCompleted(false);
                setShowRestoreDefaults(null);
                loadPropertyProfile().then(() => setActiveStep(null));
              });
          }}
          method={showRestoreDefaults}
          closeFunc={() => setShowRestoreDefaults(null)}
        />
      ) : null}
      {showPasswordHelp ? (
        <UserForgotPassword
          email={serviceProfile.Header.UpdaterEmail}
          closeFunc={() => setShowPasswordHelp(null)}
          passwordUpdatedFunc={(request) => {
            logUserIn(request.Email, request.NewPassword);
            setShowPasswordHelp(null);
          }}
        />
      ) : null}
      {showLoginForm ? (
        <LoginFormPopup
          closeFunc={() => setShowLoginForm(null)}
          afterLoginFunc={(result) => {
            setShowLoginForm(null);
          }}
        />
      ) : null}
      {showPropertySelect ? (
        <UserPropertySelect
          selectedProperty={serviceProfile.Header.PropertyID}
          lastRoute={showPropertySelect}
          closeFunc={() => setShowPropertySelect(null)}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    stateList: state.commonData.stateList,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      ),
      userLoggedIn: bindActionCreators(userAuthActions.userLoggedIn, dispatch),
      logOutUser: bindActionCreators(userAuthActions.logOutUser, dispatch),
      clearResidentOnboarding: bindActionCreators(
        residentOnboardingActions.clearResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyProfile);
