import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert } from '@mui/material';

import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputMask from 'react-input-mask';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import SearchIcon from '@mui/icons-material/Search';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckIcon from '@mui/icons-material/Check';

import Switch from '@mui/material/Switch';

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
  vehicleField,
  petField,
  dayOfWeekList,
  businessHourList
} from '../ServiceProfileShared/config';

import { getPlaceholderImage } from '../ResidentOnboarding/ResidentOnboardingPhotoUpload';

export default function RequiredTasks({
  serviceProfile,
  setServiceProfile,
  authUser,
  siteConfig
}) {
  const [requiredItemExpand, setRequiredItemExpand] = useState({
    VehicleRegistrationInclude: false,
    PetInclude: false
  });

  const [adminEdit, setAdminEdit] = useState({
    VehicleRegistrationInclude: false,
    PetInclude: false
  });

  return (
    <div className="form required-tasks">
      <div className="section">
        <h3>Required Tasks</h3>
        <Alert severity="info">
          <p>
            Select the tasks that residents are required to complete before they
            can move in. Please go to each item's 'Required Items' section below
            and select all that apply.
          </p>
        </Alert>
      </div>

      <div
        className={
          'section proof collapsible' +
          (serviceProfile.Header.VehicleRegistrationInclude &&
          requiredItemExpand.VehicleRegistrationInclude
            ? ' expanded'
            : '')
        }>
        <h3>
          {serviceProfile.Header.VehicleRegistrationInclude ? (
            <Button
              className="expand-button"
              onClick={() =>
                setRequiredItemExpand({
                  ...requiredItemExpand,
                  VehicleRegistrationInclude:
                    !requiredItemExpand.VehicleRegistrationInclude
                })
              }>
              View Settings <ExpandMoreIcon />
            </Button>
          ) : null}
          <FormControlLabel
            control={
              <Checkbox
                checked={serviceProfile.Header.VehicleRegistrationInclude}
                onChange={(event) => {
                  const checked = event.target.checked;
                  let _serviceProfile = {
                    ...serviceProfile,
                    Header: {
                      ...serviceProfile.Header,
                      VehicleRegistrationInclude: checked,
                      VehicleRegistrationIncludeCheckbox: checked
                    }
                  };

                  if (checked) {
                    Object.keys(vehicleField).map((field) => {
                      switch (field) {
                        case 'VehicleRegistrationIncludeVIN':
                        case 'VehicleRegistrationIncludeProofOfInsurance':
                          break;
                        default:
                          _serviceProfile.Header[field] = true;
                          break;
                      }
                    });
                  }

                  setServiceProfile(_serviceProfile);
                }}
              />
            }
            label={<div>Vehicle Registration</div>}
          />
        </h3>

        <div className="form-content with-photo-option">
          <Alert severity="warning" className="admin-edit">
            {authUser && authUser.User && authUser.User.IsAdmin ? (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() =>
                  setAdminEdit({
                    ...adminEdit,
                    VehicleRegistrationInclude:
                      !adminEdit.VehicleRegistrationInclude
                  })
                }>
                {adminEdit.VehicleRegistrationInclude
                  ? 'Stop Editing'
                  : 'Admin Edit'}
              </Button>
            ) : null}
            The selections below were set by default to be consistent across all
            properties. If you need to make a change to these settings, please
            contact your {siteConfig.brand} account manager at{' '}
            <a href={'tel:' + siteConfig.phone} className="link">
              {siteConfig.phone}
            </a>{' '}
            or email{' '}
            <a href={'mailto:' + siteConfig.email} className="link">
              {siteConfig.email}
            </a>
            .
          </Alert>

          <div
            className={
              'form-options' +
              (!adminEdit.VehicleRegistrationInclude ? ' disabled' : '')
            }>
            <p>
              Select the options below that the new residents are required to
              submit.
            </p>
            {Object.keys(vehicleField).map((field) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={serviceProfile.Header[field]}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          [field]: event.target.checked
                        }
                      })
                    }
                  />
                }
                label={<div>{vehicleField[field]}</div>}
              />
            ))}

            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    serviceProfile.Header.VehicleRegistrationIncludeCheckbox
                  }
                  onChange={(event) =>
                    setServiceProfile({
                      ...serviceProfile,
                      Header: {
                        ...serviceProfile.Header,
                        VehicleRegistrationIncludeCheckbox: event.target.checked
                      }
                    })
                  }
                />
              }
              label={
                <div>
                  {'Resident Acknowledgment of Vehicle Insurance Requirements'}
                  <TextField
                    multiline
                    variant="outlined"
                    label="Acknowledgment Text"
                    disabled={
                      !serviceProfile.Header.VehicleRegistrationIncludeCheckbox
                    }
                    fullWidth
                    style={{
                      marginTop: '20px',
                      width: '400px'
                    }}
                    value={
                      serviceProfile.Header.VehicleRegistrationCheckboxText
                    }
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          VehicleRegistrationCheckboxText: event.target.value
                        }
                      })
                    }
                  />
                </div>
              }
            />
          </div>
          <div
            className={
              'form-angles' +
              (!adminEdit.VehicleRegistrationInclude ? ' disabled' : '')
            }>
            <p>
              <strong>Vehicle Photos</strong> - if you'd like residents to
              submit photos of their vehicles, choose one or more angles from
              the options below.
            </p>

            <div className="angle">
              {[getPlaceholderImage('Front')].map((image) => (
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url(' + image.url + ')',
                    backgroundSize: image.size
                  }}></div>
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      serviceProfile.Header.VehicleRegistrationPhotoIncludeFront
                    }
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          VehicleRegistrationPhotoIncludeFront:
                            event.target.checked
                        }
                      })
                    }
                  />
                }
                label={
                  <div>
                    <div className="label">Front</div>
                  </div>
                }
              />
            </div>

            <div className="angle">
              {' '}
              {[getPlaceholderImage('Back')].map((image) => (
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url(' + image.url + ')',
                    backgroundSize: image.size
                  }}></div>
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      serviceProfile.Header.VehicleRegistrationPhotoIncludeBack
                    }
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          VehicleRegistrationPhotoIncludeBack:
                            event.target.checked
                        }
                      })
                    }
                  />
                }
                label={
                  <div>
                    <div className="label">Back</div>
                  </div>
                }
              />
            </div>

            <div className="angle">
              {[getPlaceholderImage('Side')].map((image) => (
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url(' + image.url + ')',
                    backgroundSize: image.size
                  }}></div>
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      serviceProfile.Header.VehicleRegistrationPhotoIncludeSide
                    }
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          VehicleRegistrationPhotoIncludeSide:
                            event.target.checked
                        }
                      })
                    }
                  />
                }
                label={
                  <div>
                    <div className="label">Side</div>
                  </div>
                }
              />
            </div>

            <div className="angle">
              {[getPlaceholderImage('Angled')].map((image) => (
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url(' + image.url + ')',
                    backgroundSize: image.size
                  }}></div>
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      serviceProfile.Header.VehicleRegistrationPhotoIncludeAny
                    }
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          VehicleRegistrationPhotoIncludeAny:
                            event.target.checked
                        }
                      })
                    }
                  />
                }
                label={
                  <div>
                    <div className="label">Angled</div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          'section proof collapsible' +
          (serviceProfile.Header.PetInclude && requiredItemExpand.PetInclude
            ? ' expanded'
            : '')
        }>
        <h3>
          {serviceProfile.Header.PetInclude ? (
            <Button
              className="expand-button"
              onClick={() =>
                setRequiredItemExpand({
                  ...requiredItemExpand,
                  PetInclude: !requiredItemExpand.PetInclude
                })
              }>
              View Settings <ExpandMoreIcon />
            </Button>
          ) : null}

          <FormControlLabel
            control={
              <Checkbox
                checked={serviceProfile.Header.PetInclude}
                onChange={(event) => {
                  const checked = event.target.checked;
                  let _serviceProfile = {
                    ...serviceProfile,
                    Header: {
                      ...serviceProfile.Header,
                      PetInclude: checked,
                      PetPolicyIncludeCheckbox: checked,
                      PetLiabilityIncludeCheckbox: checked
                    }
                  };

                  if (checked) {
                    Object.keys(petField).map((field) => {
                      switch (field) {
                        case 'PetIncludeProofOfVaccinations':
                        case 'PetIncludeSpayedNeutered':
                          break;
                        default:
                          _serviceProfile.Header[field] = true;
                          break;
                      }
                    });
                  }

                  setServiceProfile(_serviceProfile);
                }}
              />
            }
            label={<div>Pet Registration</div>}
          />
        </h3>

        <div className="form-content  with-photo-option">
          <Alert severity="warning" className="admin-edit">
            {authUser && authUser.User && authUser.User.IsAdmin ? (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() =>
                  setAdminEdit({
                    ...adminEdit,
                    PetInclude: !adminEdit.PetInclude
                  })
                }>
                {adminEdit.PetInclude ? 'Stop Editing' : 'Admin Edit'}
              </Button>
            ) : null}
            The selections below were set by default to be consistent across all
            properties. If you need to make a change to these settings, please
            contact your {siteConfig.brand} account manager at{' '}
            <a href={'tel:' + siteConfig.phone} className="link">
              {siteConfig.phone}
            </a>{' '}
            or email{' '}
            <a href={'mailto:' + siteConfig.email} className="link">
              {siteConfig.email}
            </a>
            .
          </Alert>

          <div
            className={
              'form-options' + (!adminEdit.PetInclude ? ' disabled' : '')
            }>
            <p>
              Select the options below that the new residents are required to
              submit.
            </p>
            {Object.keys(petField).map((field) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={serviceProfile.Header[field]}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          [field]: event.target.checked
                        }
                      })
                    }
                  />
                }
                label={<div>{petField[field]}</div>}
              />
            ))}

            <FormControlLabel
              control={
                <Checkbox
                  checked={serviceProfile.Header.PetInterviewRequired}
                  onChange={(event) =>
                    setServiceProfile({
                      ...serviceProfile,
                      Header: {
                        ...serviceProfile.Header,
                        PetInterviewRequired: event.target.checked
                      }
                    })
                  }
                />
              }
              label={
                <div>
                  {'Reminder of Pet Interview Requirement'}
                  <TextField
                    multiline
                    variant="outlined"
                    label="Requirement Text"
                    disabled={!serviceProfile.Header.PetInterviewRequired}
                    fullWidth
                    style={{
                      marginTop: '20px',
                      width: '400px'
                    }}
                    value={serviceProfile.Header.PetInterviewText}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          PetInterviewText: event.target.value
                        }
                      })
                    }
                  />
                </div>
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={serviceProfile.Header.PetPolicyIncludeCheckbox}
                  onChange={(event) =>
                    setServiceProfile({
                      ...serviceProfile,
                      Header: {
                        ...serviceProfile.Header,
                        PetPolicyIncludeCheckbox: event.target.checked
                      }
                    })
                  }
                />
              }
              label={
                <div>
                  {'Resident Acknowledgment of Pet Policy'}
                  <TextField
                    multiline
                    variant="outlined"
                    label="Acknowledgment Text"
                    disabled={!serviceProfile.Header.PetPolicyIncludeCheckbox}
                    fullWidth
                    style={{
                      marginTop: '20px',
                      width: '400px'
                    }}
                    value={serviceProfile.Header.PetPolicyText}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          PetPolicyText: event.target.value
                        }
                      })
                    }
                  />
                </div>
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={serviceProfile.Header.PetLiabilityIncludeCheckbox}
                  onChange={(event) =>
                    setServiceProfile({
                      ...serviceProfile,
                      Header: {
                        ...serviceProfile.Header,
                        PetLiabilityIncludeCheckbox: event.target.checked
                      }
                    })
                  }
                />
              }
              label={
                <div>
                  {'Resident Acknowledgment of Pet Liability'}
                  <TextField
                    multiline
                    variant="outlined"
                    label="Acknowledgment Text"
                    disabled={
                      !serviceProfile.Header.PetLiabilityIncludeCheckbox
                    }
                    fullWidth
                    style={{
                      marginTop: '20px',
                      width: '400px'
                    }}
                    value={serviceProfile.Header.PetLiabilityText}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          PetLiabilityText: event.target.value
                        }
                      })
                    }
                  />
                </div>
              }
            />
          </div>

          <div
            className={
              'form-angles' + (!adminEdit.PetInclude ? ' disabled' : '')
            }>
            <p>
              <strong>Pet Photos</strong> - if you'd like residents to submit
              photos of their pets, choose one or more angles from the options
              below.
            </p>

            <div className="angle">
              {[getPlaceholderImage('Face')].map((image) => (
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url(' + image.url + ')',
                    backgroundSize: image.size
                  }}></div>
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={serviceProfile.Header.PetPhotoIncludeFace}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          PetPhotoIncludeFace: event.target.checked
                        }
                      })
                    }
                  />
                }
                label={
                  <div>
                    <div className="label">Face</div>
                  </div>
                }
              />
            </div>

            <div className="angle">
              {[getPlaceholderImage('Standing/Side')].map((image) => (
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url(' + image.url + ')',
                    backgroundSize: image.size
                  }}></div>
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={serviceProfile.Header.PetPhotoIncludeStanding}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          PetPhotoIncludeStanding: event.target.checked
                        }
                      })
                    }
                  />
                }
                label={
                  <div>
                    <div className="label">Standing/Side</div>
                  </div>
                }
              />
            </div>

            <div className="angle">
              {[getPlaceholderImage('Favorite 1')].map((image) => (
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url(' + image.url + ')',
                    backgroundSize: image.size
                  }}></div>
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={serviceProfile.Header.PetPhotoIncludeSitting}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          PetPhotoIncludeSitting: event.target.checked
                        }
                      })
                    }
                  />
                }
                label={
                  <div>
                    <div className="label">Optional Favorite 1</div>
                  </div>
                }
              />
            </div>

            <div className="angle">
              {[getPlaceholderImage('Favorite 2')].map((image) => (
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url(' + image.url + ')',
                    backgroundSize: image.size
                  }}></div>
              ))}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={serviceProfile.Header.PetPhotoIncludeAny}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        Header: {
                          ...serviceProfile.Header,
                          PetPhotoIncludeAny: event.target.checked
                        }
                      })
                    }
                  />
                }
                label={
                  <div>
                    <div className="label">Optional Favorite 2</div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>

      {serviceProfile.CalendarList.map((calendar, calendarIndex) => (
        <div
          className={
            'section proof calendar collapsible' +
            (calendar.Include && requiredItemExpand['calendar-' + calendarIndex]
              ? ' expanded'
              : '')
          }
          key={'calendar-' + calendarIndex}>
          <h3>
            {calendar.Include ? (
              <Button
                className="expand-button"
                onClick={() =>
                  setRequiredItemExpand({
                    ...requiredItemExpand,
                    ['calendar-' + calendarIndex]:
                      !requiredItemExpand['calendar-' + calendarIndex]
                  })
                }>
                View Settings <ExpandMoreIcon />
              </Button>
            ) : null}

            <FormControlLabel
              control={
                <Checkbox
                  checked={calendar.Include}
                  onChange={(event) => {
                    setServiceProfile({
                      ...serviceProfile,
                      CalendarList: serviceProfile.CalendarList.map(
                        (_calendar, _calendarIndex) =>
                          _calendarIndex === calendarIndex
                            ? {
                                ..._calendar,
                                Include: event.target.checked
                              }
                            : { ..._calendar }
                      )
                    });

                    setRequiredItemExpand({
                      ...requiredItemExpand,
                      ['calendar-' + calendarIndex]: true
                    });
                  }}
                />
              }
              label={<div>{calendar.CalendarName}</div>}
            />
          </h3>

          <div className="form-content">
            {/* <Alert severity="warning" className="admin-edit">
              {authUser && authUser.User && authUser.User.IsAdmin ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() =>
                    setAdminEdit({
                      ...adminEdit,
                      ['calendar-' + calendar.CalendarName]:
                        !adminEdit['calendar-' + calendar.CalendarName]
                    })
                  }>
                  {adminEdit['calendar-' + calendar.CalendarName]
                    ? 'Stop Editing'
                    : 'Admin Edit'}
                </Button>
              ) : null}
              To change these settings, please contact your {siteConfig.brand}{' '}
              account manager at{' '}
              <a href={'tel:' + siteConfig.phone} className="link">
                {siteConfig.phone}
              </a>{' '}
              or email{' '}
              <a href={'mailto:' + siteConfig.email} className="link">
                {siteConfig.email}
              </a>
              .
            </Alert> */}

            <div
              className={
                'form-options'
                // +
                // (!adminEdit['calendar-' + calendar.CalendarName]
                //   ? ' disabled'
                //   : '')
              }>
              {calendar.CalendarType === 'custom' ? (
                <>
                  <TextField
                    value={calendar.CalendarName}
                    label="Calendar Title"
                    style={{ marginBottom: '20px' }}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        CalendarList: serviceProfile.CalendarList.map(
                          (_calendar, _calendarIndex) =>
                            _calendarIndex === calendarIndex
                              ? {
                                  ..._calendar,
                                  CalendarName: event.target.value
                                    ? event.target.value
                                    : calendar.OriginalName,
                                  OriginalName: calendar.OriginalName
                                    ? calendar.OriginalName
                                    : calendar.CalendarName
                                }
                              : { ..._calendar }
                        )
                      })
                    }
                    fullWidth
                  />

                  <TextField
                    value={calendar.Abbreviation}
                    label="Short Title"
                    style={{ marginBottom: '20px' }}
                    onChange={(event) =>
                      setServiceProfile({
                        ...serviceProfile,
                        CalendarList: serviceProfile.CalendarList.map(
                          (_calendar, _calendarIndex) =>
                            _calendarIndex === calendarIndex
                              ? {
                                  ..._calendar,
                                  Abbreviation: event.target.value
                                }
                              : { ..._calendar }
                        )
                      })
                    }
                    placeholder={
                      calendar.CalendarName
                        ? 'e.g. ' + calendar.CalendarName.split(' ')[0]
                        : ''
                    }
                    helperText="Shorter name for displaying events alongside other calendars"
                    fullWidth
                  />
                </>
              ) : null}

              <RadioGroup
                row
                value={calendar.TimeslotLengthMinutes}
                onChange={(event) =>
                  setServiceProfile({
                    ...serviceProfile,
                    CalendarList: serviceProfile.CalendarList.map(
                      (_calendar, _calendarIndex) =>
                        _calendarIndex === calendarIndex
                          ? {
                              ..._calendar,
                              TimeslotLengthMinutes: event.target.value
                            }
                          : { ..._calendar }
                    )
                  })
                }>
                <p>
                  What is the length of time for each{' '}
                  {calendar.Term.toLowerCase()}?
                </p>
                <div>
                  <FormControlLabel
                    value={30}
                    control={<Radio />}
                    label={'30 Minutes'}
                  />

                  <FormControlLabel
                    value={60}
                    control={<Radio />}
                    label={'1 Hour'}
                  />

                  <FormControlLabel
                    value={90}
                    control={<Radio />}
                    label={'90 Minutes'}
                  />

                  <FormControlLabel
                    value={120}
                    control={<Radio />}
                    label={'2 hours'}
                  />
                </div>
              </RadioGroup>

              <RadioGroup
                row
                value={calendar.TimeslotMaxAllowed}
                onChange={(event) =>
                  setServiceProfile({
                    ...serviceProfile,
                    CalendarList: serviceProfile.CalendarList.map(
                      (_calendar, _calendarIndex) =>
                        _calendarIndex === calendarIndex
                          ? {
                              ..._calendar,
                              TimeslotMaxAllowed: event.target.value
                            }
                          : { ..._calendar }
                    )
                  })
                }>
                <p>
                  What is the maximum number of {calendar.Term.toLowerCase()}s
                  allowed per resident?
                </p>
                <div>
                  <FormControlLabel value={1} control={<Radio />} label={'1'} />

                  <FormControlLabel value={2} control={<Radio />} label={'2'} />

                  <FormControlLabel value={3} control={<Radio />} label={'3'} />

                  <FormControlLabel value={4} control={<Radio />} label={'4'} />
                </div>
              </RadioGroup>

              <p>What hours are {calendar.Term.toLowerCase()}s available?</p>
              <div className="days-of-week">
                {dayOfWeekList.map((day) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={calendar[day + 'StartTime']}
                        onChange={(event) =>
                          setServiceProfile({
                            ...serviceProfile,
                            CalendarList: serviceProfile.CalendarList.map(
                              (_calendar, _calendarIndex) =>
                                _calendarIndex === calendarIndex
                                  ? {
                                      ..._calendar,

                                      [day + 'StartTime']: event.target.checked
                                        ? dayOfWeekList
                                            .filter(
                                              (_day) =>
                                                calendar[_day + 'StartTime']
                                            )
                                            .map(
                                              (_day) =>
                                                calendar[_day + 'StartTime']
                                            )
                                            .concat('9:00 am')[0]
                                        : '',
                                      [day + 'EndTime']: event.target.checked
                                        ? dayOfWeekList
                                            .filter(
                                              (_day) =>
                                                calendar[_day + 'EndTime']
                                            )
                                            .map(
                                              (_day) =>
                                                calendar[_day + 'EndTime']
                                            )
                                            .concat('6:00 pm')[0]
                                        : ''
                                    }
                                  : { ..._calendar }
                            )
                          })
                        }
                      />
                    }
                    label={
                      <div>
                        <div className="right">
                          {['Start Time', 'End Time'].map((field) => (
                            <FormControl
                              disabled={!calendar[day + 'StartTime']}
                              variant="outlined"
                              className="state">
                              <InputLabel
                                id={
                                  'label_' +
                                  field.split(' ').join('') +
                                  '_' +
                                  day +
                                  '_' +
                                  calendar.CalendarName.split(' ').join('')
                                }>
                                {field}
                              </InputLabel>
                              <Select
                                label={field}
                                labelId={
                                  'label_' +
                                  field.split(' ').join('') +
                                  '_' +
                                  day +
                                  '_' +
                                  calendar.CalendarName.split(' ').join('')
                                }
                                id={
                                  field.split(' ').join('') +
                                  '_' +
                                  day +
                                  '_' +
                                  calendar.CalendarName.split(' ').join('')
                                }
                                value={
                                  calendar[day + field.split(' ').join('')]
                                }
                                onChange={(event) =>
                                  setServiceProfile({
                                    ...serviceProfile,
                                    CalendarList:
                                      serviceProfile.CalendarList.map(
                                        (_calendar, _calendarIndex) =>
                                          _calendarIndex === calendarIndex
                                            ? {
                                                ..._calendar,
                                                [day +
                                                field.split(' ').join('')]:
                                                  event.target.value
                                              }
                                            : { ..._calendar }
                                      )
                                  })
                                }>
                                {businessHourList.map((time, timeIndex) => (
                                  <MenuItem value={time}>{time}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ))}
                        </div>
                        {day}
                      </div>
                    }
                  />
                ))}
              </div>
              <TextField
                value={calendar.Description}
                label="Message to Resident"
                style={{ marginTop: '20px' }}
                multiline
                onChange={(event) =>
                  setServiceProfile({
                    ...serviceProfile,
                    CalendarList: serviceProfile.CalendarList.map(
                      (_calendar, _calendarIndex) =>
                        _calendarIndex === calendarIndex
                          ? {
                              ..._calendar,
                              Description: event.target.value
                            }
                          : { ..._calendar }
                    )
                  })
                }
                fullWidth
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
