import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { withPrefix } from 'gatsby-link';
import moment from 'moment';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, TextField } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputMask from 'react-input-mask';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';

import * as dataApi from '../../api/dataApi';
import { saveSmarterChoiceSettings } from '../../api/propertyProfileApi';

import GeneralPopup from '../GeneralPopup';
import MarketingAgreement from '../PartnerMultifamily/MarketingAgreement';

import './SCPropertyDashboard.css';

function SCPropertyDashboard({
  actions,
  siteConfig,
  webSocket,
  authUser,
  profileKey,
  propertyProfile,
  loadPropertyProfile
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [baseContactRequest, setBaseContactRequest] = useState(null);
  const [contactRequest, setContactRequest] = useState(null);
  const [submitting, setSubmitting] = useState(null);

  console.log('SCPropertyDashboard', propertyProfile);

  useEffect(() => {
    if (propertyProfile && propertyProfile.Property) {
      setBaseContactRequest({
        'Property Name': propertyProfile.Property.PropertyName
      });
    }
  }, [propertyProfile]);

  function checkDisableSave() {
    if (submitting) {
      return true;
    }

    switch (activeStep) {
      case 0:
        return (
          !contactRequest['First Name'] ||
          !contactRequest['Last Name'] ||
          !contactRequest['Title'] ||
          !contactRequest['Email'] ||
          !contactRequest['Phone'] ||
          contactRequest['Phone'].indexOf('_') !== -1 ||
          !contactRequest['Property Name'] ||
          !contactRequest['Property Street Address'] ||
          !contactRequest['Property City, State Zip'] ||
          !contactRequest['Number of Residential Units'] ||
          !contactRequest['Management Company']
        );
      case 1:
        return (
          !contactRequest['Electronic Signature'] ||
          !contactRequest['Legal Name'] ||
          contactRequest['Checkbox Checked'] !== 'Yes'
        );
      case 2:
        if (contactRequest['Where do we send your commission payments?']) {
          if (
            contactRequest['Where do we send your commission payments?'] ===
            'Other Address'
          ) {
            return (
              !contactRequest['Commission Street Address'] ||
              !contactRequest['Commission City'] ||
              !contactRequest['Commission State'] ||
              !contactRequest['Commission Zip']
            );
          }

          return false;
        }

        return true;
      case 3:
        if (
          contactRequest[
            'How would you like to share your new resident data with us?'
          ]
        ) {
          if (
            contactRequest[
              'How would you like to share your new resident data with us?'
            ].indexOf('Direct') !== -1
          ) {
            return (
              !contactRequest['Integration Contact Name'] ||
              !contactRequest['Integration Contact Title'] ||
              !contactRequest['Integration Contact Email'] ||
              !contactRequest['Integration Contact Phone'] ||
              contactRequest['Integration Contact Phone'].indexOf('_') !== -1
            );
          }

          return false;
        }
        return true;
    }
  }

  return (
    <>
      <div className={'sc-property-dashboard'}>
        {!propertyProfile.SmarterChoiceSettings ||
        !propertyProfile.SmarterChoiceSettings.IsPaymentInfoCompleted ||
        !propertyProfile.SmarterChoiceSettings.IsAgreementCompleted ||
        !propertyProfile.SmarterChoiceSettings.IsDataSharingCompleted ? (
          <div className="item-list">
            <p>
              <strong>Items needed to complete registration</strong>
            </p>

            {!propertyProfile.SmarterChoiceSettings ||
            !propertyProfile.SmarterChoiceSettings.IsPaymentInfoCompleted ? (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  setContactRequest({ ...baseContactRequest });
                  setActiveStep(2);
                }}>
                Click to confirm commission payment details
              </Button>
            ) : null}

            {!propertyProfile.SmarterChoiceSettings ||
            !propertyProfile.SmarterChoiceSettings.IsAgreementCompleted ? (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  setContactRequest({
                    ...baseContactRequest,
                    ['Legal Name']: propertyProfile.Property.PropertyName,
                    ['Date Signed']: moment().local().format('MM/DD/YYYY')
                  });
                  setActiveStep(1);
                }}>
                Click to review and sign the Revenue Sharing Agreement
              </Button>
            ) : null}

            {!propertyProfile.SmarterChoiceSettings ||
            !propertyProfile.SmarterChoiceSettings.IsDataSharingCompleted ? (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  setContactRequest({ ...baseContactRequest });
                  setActiveStep(3);
                }}>
                Click to select method of resident data sharing
              </Button>
            ) : null}
          </div>
        ) : null}

        <h2>Order History and Current Status</h2>

        <TableContainer>
          <Table aria-label="simple table" stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Lease Start</TableCell>
                <TableCell>Unit Number</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Transaction Date</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell>Order Number</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Pending Commission</TableCell>
                <TableCell>Provider Payment Received</TableCell>
                <TableCell>Due Client</TableCell>
                <TableCell>Accounting Period</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={13}>No data to report</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {activeStep === 1 ? (
        <GeneralPopup
          title={'Revenue Sharing Agreement'}
          className={'sc-property-dashboard-popup'}
          message={
            <>
              <h4>
                Confirm legal name - this name will be used in the Revenue
                Sharing Agreement
              </h4>

              <TextField
                required
                variant="outlined"
                fullWidth
                label={'Type your name'}
                value={contactRequest['Legal Name']}
                onChange={(event) =>
                  setContactRequest({
                    ...contactRequest,
                    ['Legal Name']: event.target.value
                  })
                }
              />

              <h4>Review and accept the Marketing Agreement below</h4>

              <Button
                className="print-button print"
                href={withPrefix(
                  '/images/company/doc-download/SmarterChoice Marketing Agreement.pdf'
                )}
                onClick={(event) => event.stopPropagation()}
                target="_blank">
                <PictureAsPdfIcon style={{ marginRight: '5px' }} /> Open as
                Printable PDF
              </Button>

              <div className="agreement">
                <MarketingAgreement
                  propertyName={
                    contactRequest['Legal Name']
                      ? contactRequest['Legal Name']
                      : propertyProfile.Property.PropertyName
                  }
                />
              </div>

              <div className="checkbox-container">
                <FormControlLabel
                  style={{ display: 'table' }}
                  control={
                    <div>
                      <Checkbox
                        checked={contactRequest['Checkbox Checked'] === 'Yes'}
                        onChange={(event) =>
                          setContactRequest({
                            ...contactRequest,
                            'Checkbox Checked': event.target.checked
                              ? 'Yes'
                              : 'No'
                          })
                        }
                        name="gilad"
                      />
                    </div>
                  }
                  label={
                    <div className="checkbox-text">
                      <strong>
                        I understand and agree that by clicking here and typing
                        my name below, I am consenting to the use of this
                        digital form and intending to electronically sign this
                        form.
                      </strong>
                    </div>
                  }
                />

                <div className="field-container">
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    label={'Type your name'}
                    value={contactRequest['Electronic Signature']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Electronic Signature']: event.target.value
                      })
                    }
                  />
                </div>

                <div className="field-container">
                  <InputMask
                    mask="99/99/9999"
                    maskChar={'_'}
                    disabled
                    value={contactRequest['Date Signed']}>
                    {() => (
                      <TextField
                        fullWidth
                        label={'Date Signed'}
                        required
                        inputProps={{
                          inputMode: 'numeric'
                        }}
                        disabled
                      />
                    )}
                  </InputMask>
                </div>
              </div>
            </>
          }
          submitDisabled={checkDisableSave()}
          submitLabel={submitting ? 'Submitting...' : 'Submit Form'}
          submitFunc={() => {
            dataApi
              .saveInquiry(
                'SmarterChoice Revenue Sharing Agreement Form',
                contactRequest
              )
              .then((result) => {
                saveSmarterChoiceSettings(profileKey, {
                  ...propertyProfile.SmarterChoiceSettings,
                  IsAgreementCompleted: true
                }).then(() => {
                  loadPropertyProfile();
                  setActiveStep(0);
                });
              });
          }}
          closeFunc={() => setActiveStep(0)}
          closeLabel={'Cancel'}
        />
      ) : activeStep === 2 ? (
        <GeneralPopup
          title={'Commission Payment Details'}
          className={'sc-property-dashboard-popup'}
          message={
            <>
              <h4>Where do we send your commission payments?</h4>
              <FormControl component="fieldset">
                <RadioGroup
                  value={
                    contactRequest['Where do we send your commission payments?']
                  }
                  onChange={(event, newValue) => {
                    setContactRequest({
                      ...contactRequest,
                      ['Where do we send your commission payments?']: newValue
                    });
                  }}>
                  <FormControlLabel
                    value={'Property Address'}
                    className={
                      contactRequest[
                        'Where do we send your commission payments?'
                      ] === 'Property Address'
                        ? 'selected'
                        : ''
                    }
                    control={<Radio color="primary" />}
                    label={
                      <>
                        <strong>Property Address</strong> <br /> <br />
                        {propertyProfile.Property.Address}{' '}
                        {propertyProfile.Property.Secondary}
                        <br />
                        {propertyProfile.Property.City},{' '}
                        {propertyProfile.Property.State}{' '}
                        {propertyProfile.Property.Zip}
                      </>
                    }
                  />

                  <FormControlLabel
                    value="Other Address"
                    className={
                      contactRequest[
                        'Where do we send your commission payments?'
                      ] === 'Other'
                        ? 'selected'
                        : ''
                    }
                    control={<Radio color="primary" />}
                    label={
                      <>
                        <strong>Other Address (enter below)</strong>

                        <TextField
                          variant="outlined"
                          label="Commission Street Address"
                          name="address"
                          fullWidth
                          disabled={
                            contactRequest[
                              'Where do we send your commission payments?'
                            ] !== 'Other Address'
                          }
                          value={contactRequest['Commission Street Address']}
                          onChange={(event) =>
                            setContactRequest({
                              ...contactRequest,
                              ['Commission Street Address']: event.target.value
                            })
                          }
                          required
                        />

                        <TextField
                          variant="outlined"
                          name="city"
                          label="City"
                          fullWidth
                          disabled={
                            contactRequest[
                              'Where do we send your commission payments?'
                            ] !== 'Other Address'
                          }
                          value={contactRequest['City']}
                          onChange={(event) =>
                            setContactRequest({
                              ...contactRequest,
                              ['Commission City']: event.target.value
                            })
                          }
                          style={{ width: '56%' }}
                          required
                        />

                        <TextField
                          variant="outlined"
                          name="state"
                          label="State"
                          fullWidth
                          disabled={
                            contactRequest[
                              'Where do we send your commission payments?'
                            ] !== 'Other Address'
                          }
                          value={contactRequest['State']}
                          onChange={(event) =>
                            setContactRequest({
                              ...contactRequest,
                              ['Commission State']: event.target.value
                            })
                          }
                          style={{ width: '20%', marginLeft: '2%' }}
                          required
                        />

                        <TextField
                          variant="outlined"
                          name="zip"
                          label="Zip"
                          fullWidth
                          disabled={
                            contactRequest[
                              'Where do we send your commission payments?'
                            ] !== 'Other Address'
                          }
                          value={contactRequest['Zip']}
                          onChange={(event) =>
                            setContactRequest({
                              ...contactRequest,
                              ['Commission Zip']: event.target.value
                            })
                          }
                          style={{ width: '20%', marginLeft: '2%' }}
                          required
                        />
                      </>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </>
          }
          submitDisabled={checkDisableSave()}
          submitLabel={submitting ? 'Submitting...' : 'Submit Form'}
          submitFunc={() => {
            dataApi
              .saveInquiry('SmarterChoice Payment Details Form', contactRequest)
              .then((result) => {
                saveSmarterChoiceSettings(profileKey, {
                  ...propertyProfile.SmarterChoiceSettings,
                  IsPaymentInfoCompleted: true
                }).then(() => {
                  loadPropertyProfile();
                  setActiveStep(0);
                });
              });
          }}
          closeFunc={() => setActiveStep(0)}
          closeLabel={'Cancel'}
        />
      ) : activeStep === 3 ? (
        <GeneralPopup
          title={'Method of Resident Data Sharing'}
          className={'sc-property-dashboard-popup'}
          message={
            <>
              <h4>
                How would you like to share your new resident data with us?
              </h4>
              <FormControl component="fieldset">
                <RadioGroup
                  value={
                    contactRequest[
                      'How would you like to share your new resident data with us?'
                    ]
                  }
                  onChange={(event, newValue) => {
                    setContactRequest({
                      ...contactRequest,
                      ['How would you like to share your new resident data with us?']:
                        newValue
                    });
                  }}>
                  {[
                    'Direct API Integration with your onsite Property Management System. This ‘hands-free’ method allows for the automatic transfers of your resident data to SmarterChoice on a daily basis, but may require your corporate software administrator to add SmarterChoice to your management system as a vendor.',
                    'Weekly Rent Roll updates. If API Integration is not an option, this method lets you manually extract a current Rent Roll from your property management system and upload it into the SmarterChoice platform via a simple email link that we send you once a week.',
                    'Manual entry of individual resident data. This method requires a bit more effort on your part, but allows for the manual entry of resident data one resident at a time. This may be a good option for smaller properties that don’t have API Integration.'
                  ].map((option, optionIndex) => (
                    <FormControlLabel
                      value={option}
                      className={
                        contactRequest[
                          'How would you like to share your new resident data with us?'
                        ] === option
                          ? 'selected'
                          : ''
                      }
                      control={<Radio color="primary" />}
                      label={
                        <>
                          {option}

                          {optionIndex === 0 &&
                          contactRequest[
                            'How would you like to share your new resident data with us?'
                          ] === option ? (
                            <>
                              <h4
                                style={{
                                  marginTop: '20px',
                                  marginBottom: '0px'
                                }}>
                                Who should we contact at your company to get
                                this set up?
                              </h4>
                              <TextField
                                variant="outlined"
                                label="Name"
                                fullWidth
                                value={
                                  contactRequest['Integration Contact Name']
                                }
                                onChange={(event) =>
                                  setContactRequest({
                                    ...contactRequest,
                                    ['Integration Contact Name']:
                                      event.target.value
                                  })
                                }
                                required
                              />

                              <TextField
                                variant="outlined"
                                label="Title"
                                fullWidth
                                value={
                                  contactRequest['Integration Contact Title']
                                }
                                onChange={(event) =>
                                  setContactRequest({
                                    ...contactRequest,
                                    ['Integration Contact Title']:
                                      event.target.value
                                  })
                                }
                                required
                              />

                              <TextField
                                variant="outlined"
                                label="Email"
                                fullWidth
                                value={
                                  contactRequest['Integration Contact Email']
                                }
                                type="email"
                                onChange={(event) =>
                                  setContactRequest({
                                    ...contactRequest,
                                    ['Integration Contact Email']:
                                      event.target.value
                                  })
                                }
                                required
                              />

                              <InputMask
                                mask="999-999-9999"
                                maskChar={'_'}
                                value={
                                  contactRequest['Integration Contact Phone']
                                }
                                onChange={(event) =>
                                  setContactRequest({
                                    ...contactRequest,
                                    ['Integration Contact Phone']:
                                      event.target.value
                                  })
                                }>
                                {() => (
                                  <TextField
                                    fullWidth
                                    label={'Phone'}
                                    required
                                    inputProps={{
                                      inputMode: 'numeric'
                                    }}
                                  />
                                )}
                              </InputMask>
                            </>
                          ) : null}
                        </>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </>
          }
          submitDisabled={checkDisableSave()}
          submitLabel={submitting ? 'Submitting...' : 'Submit Form'}
          submitFunc={() => {
            dataApi
              .saveInquiry(
                'SmarterChoice Resident Data Sharing',
                contactRequest
              )
              .then((result) => {
                saveSmarterChoiceSettings(profileKey, {
                  ...propertyProfile.SmarterChoiceSettings,
                  IsDataSharingCompleted: true
                }).then(() => {
                  loadPropertyProfile();
                  setActiveStep(0);
                });
              });
          }}
          closeFunc={() => setActiveStep(0)}
          closeLabel={'Cancel'}
        />
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    stateList: state.commonData.stateList,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      userLoggedIn: bindActionCreators(userAuthActions.userLoggedIn, dispatch),
      logOutUser: bindActionCreators(userAuthActions.logOutUser, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SCPropertyDashboard);
