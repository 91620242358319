import React, { useEffect, useRef, useState } from 'react';
import { withPrefix } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import { loadResidentOnboarding } from '../../redux/actions/residentOnboardingActions';

import ResidentOnboardingVehicle from '../ResidentOnboarding/ResidentOnboardingVehicle';
import ResidentOnboardingHeader from '../ResidentOnboarding/ResidentOnboardingHeader';

import './index.css';

function VehicleProfile({
  residentOnboarding,
  vehicle,
  closeFunc,
  actions,
  profileKey
}) {
  useEffect(() => {
    actions.loadResidentOnboarding(vehicle.ResidentID);
  }, []);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="resident-profile-popup"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        Vehicle Profile for {vehicle.Model}
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <ResidentOnboardingHeader resident={vehicle} />
        {residentOnboarding &&
        residentOnboarding.Header.ResidentID === vehicle.ResidentID ? (
          <ResidentOnboardingVehicle
            residentOnboarding={residentOnboarding}
            refreshResidentOnboarding={actions.loadResidentOnboarding}
            residentID={vehicle.ResidentID}
            residentVehicleID={vehicle.ResidentVehicleID}
            profileKey={profileKey}
          />
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          style={{ float: 'left' }}
          variant="contained"
          color="primary"
          href={
            process.env.GATSBY_EXPORT_URL +
            'docs/vehicle?property=' +
            profileKey +
            '&resident=' +
            vehicle.ResidentID +
            '&residentVehicleID=' +
            vehicle.ResidentVehicleID
          }>
          Print &nbsp;&nbsp;
          <PrintIcon />
        </Button>
        <Button
          onClick={() => {
            closeFunc();
          }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    residentOnboarding: state.residentOnboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadResidentOnboarding: bindActionCreators(
        loadResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleProfile);
