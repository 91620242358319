import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import { LinearProgress, IconButton, Button } from '@mui/material';

import { scrollToElementInPage } from '../../api/dataApi';
import { listResidentPet } from '../../api/propertyProfileApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import PropertyFilterCard from '../PropertyFilterGrid/PropertyFilterCard';
import PropertyFilter from '../PropertyFilterGrid/PropertyFilter';
import PetProfile from './PetProfile';

import {
  petTypeList,
  genderList,
  colorList,
  sizeList,
  coatLengthList
} from '../ResidentOnboarding/ResidentOnboardingPet';

import { residentPassesSearch } from '../OnboardingDashboard';

import '../PropertyFilterGrid/index.css';
import './index.css';

function PropertyPets({ profileKey, searchString, setSearchString }) {
  const [petList, setPetList] = useState(null);
  const [filteredPetList, setFilteredPetList] = useState(null);
  const [filterDictionary, setFilterDictionary] = useState(null);
  const [filtersClicked, setFiltersClicked] = useState(null);
  const [showPetProfile, setShowPetProfile] = useState(null);

  useEffect(() => {
    if (profileKey) {
      setFilteredPetList(null);
      setFilterDictionary(null);
      listResidentPet(profileKey).then((_petList) =>
        setPetList(
          _petList.map((pet) => {
            let _pet = { ...pet };
            const weight = parseInt(_pet.Weight);
            if (!isNaN(weight)) {
              if (weight) {
                if (weight <= 25) {
                  _pet.Weight = 'Small (0-25 lbs)';
                } else if (weight <= 60) {
                  _pet.Weight = 'Medium (26-60 lbs)';
                } else if (weight <= 100) {
                  _pet.Weight = 'Large (61-100 lbs)';
                } else {
                  _pet.Weight = 'Extra Large (101 lbs or more)';
                }
              }
            }

            if (pet.UnitNumber === '1107') {
              console.log('1107', _pet);
            }

            return _pet;
          })
        )
      );
    }
  }, [profileKey]);

  useEffect(() => {
    if (petList) {
      let _filterDictionary = {
        View: {
          label: 'View',
          values: {},
          dropdown: true,
          selectedValue: null
        },
        PetType: {
          all: true,
          label: 'Pet Type',
          values: {},
          fullList: petTypeList
        },
        Color: {
          all: true,
          label: 'Primary Color',
          values: {},
          fullList: colorList
        },
        Weight: { all: true, label: 'Size', values: {}, fullList: sizeList },
        Gender: {
          all: true,
          label: 'Gender',
          values: {},
          fullList: genderList
        }
        // CoatLength: {
        //   all: true,

        //   label: 'Coat Length',
        //   values: {},
        //   fullList: coatLengthList
        // },
      };

      petList.map((pet) => {
        Object.keys(_filterDictionary).map((key) => {
          if (pet[key]) {
            pet[key].split(', ').map((value) => {
              if (!_filterDictionary[key].values[value]) {
                _filterDictionary[key].values[value] = {
                  total: 0
                };
              }
              _filterDictionary[key].values[value].total++;
            });
          } else if (key === 'View' && pet.FileList) {
            pet.FileList.split(',').map((descriptionFile) => {
              const description = descriptionFile.split('=>')[0];
              if (!_filterDictionary.View.values[description]) {
                _filterDictionary.View.values[description] = {
                  total: 0
                };
              }

              if (!_filterDictionary.View.selectedValue) {
                _filterDictionary.View.selectedValue = description;
              }

              _filterDictionary.View.values[description].total++;
            });
          }
        });
      });

      setFilterDictionary(_filterDictionary);

      setFilteredPetList(
        petList.map((pet) => {
          return { ...pet };
        })
      );
    }
  }, [petList]);

  useEffect(() => {
    if (filterDictionary) {
      setFilteredPetList(petList.filter((pet) => checkPassesFilter(pet)));
    }
  }, [filterDictionary, searchString]);

  useEffect(() => {
    if (typeof window !== 'undefined' && filteredPetList && filtersClicked) {
      const top = document.getElementById('filterListStart');
      if (top) {
        scrollToElementInPage(top, -145, false);
      }
    }
  }, [filteredPetList]);

  function checkPassesFilter(pet) {
    let passes = true;

    if (filterDictionary) {
      Object.keys(pet).map((column) => {
        if (filterDictionary[column] && filterDictionary[column].all !== true) {
          const values = pet[column];
          if (values) {
            let valuePasses = false;
            values.split(', ').map((value) => {
              if (filterDictionary[column].values[value] === true) {
                valuePasses = true;
              }
            });

            if (!valuePasses) {
              passes = false;
            }
          }
        }
      });
    }

    if (searchString && passes) {
      let passesSearch = false;

      let searchStringLowerCase = String(searchString).toLowerCase();

      if (residentPassesSearch({ ...pet }, searchString)) {
        passesSearch = true;
      } else if (
        pet.Name &&
        pet.Name.toLowerCase().indexOf(searchStringLowerCase) !== -1
      ) {
        passesSearch = true;
      } else if (
        pet.Breed &&
        pet.Breed.toLowerCase().indexOf(searchStringLowerCase) !== -1
      ) {
        passesSearch = true;
      }

      if (!passesSearch) {
        passes = false;
      }
    }

    return passes;
  }

  return (
    <div className={'property-pets'}>
      {filteredPetList ? (
        <div className="property-filtered-list">
          <div className="filters">
            {filterDictionary && filteredPetList ? (
              <div onClick={() => setFiltersClicked(true)}>
                {Object.keys(filterDictionary).map((key) => (
                  <PropertyFilter
                    filterKey={key}
                    filterDictionary={filterDictionary}
                    setFilterDictionary={setFilterDictionary}
                  />
                ))}
              </div>
            ) : null}
          </div>
          <div className="grid">
            <div id="filterListStart"></div>

            {filteredPetList.length ? (
              filteredPetList.map((pet) => (
                <PropertyFilterCard
                  key={pet.ResidentPetID}
                  unitNumber={pet.UnitNumber}
                  firstName={pet.FirstName}
                  lastName={pet.LastName}
                  fileListString={pet.FileList}
                  title={pet.Name ? pet.Name : 'No Name'}
                  profileLabel={'Pet Profile'}
                  profileClick={() => setShowPetProfile(pet)}
                  descriptionFilter={filterDictionary.View.selectedValue}
                />
              ))
            ) : (
              <div className="property-filtered-list-empty">
                {petList.length
                  ? 'No pets found matching the filters you selected.'
                  : 'No pets have been approved for this property.'}
              </div>
            )}
          </div>
        </div>
      ) : (
        <LinearProgress />
      )}

      {showPetProfile ? (
        <PetProfile
          pet={showPetProfile}
          closeFunc={() => setShowPetProfile(null)}
          profileKey={profileKey}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyPets);
