import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import { LinearProgress, IconButton, Button } from '@mui/material';

import { scrollToElementInPage } from '../../api/dataApi';
import { listResidentVehicle } from '../../api/propertyProfileApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import PropertyFilterCard from '../PropertyFilterGrid/PropertyFilterCard';
import PropertyFilter from '../PropertyFilterGrid/PropertyFilter';

import {
  vehicleList as allVehicleList,
  bodyStyleMap,
  getMakeList,
  getModelList,
  colorList
} from '../PropertyProfile/vehicleData';

import { residentPassesSearch } from '../OnboardingDashboard';
import VehicleProfile from './VehicleProfile';

import '../PropertyFilterGrid/index.css';
import './index.css';

function PropertyVehicles({ profileKey, searchString, setSearchString }) {
  const [vehicleList, setVehicleList] = useState(null);
  const [filteredVehicleList, setFilteredVehicleList] = useState(null);
  const [filterDictionary, setFilterDictionary] = useState(null);
  const makeFilter = filterDictionary ? filterDictionary.Make : null;
  const [filtersClicked, setFiltersClicked] = useState(null);
  const [showVehicleProfile, setShowVehicleProfile] = useState(null);

  useEffect(() => {
    if (profileKey) {
      setFilteredVehicleList(null);
      listResidentVehicle(profileKey).then((_vehicleList) =>
        setVehicleList(
          _vehicleList.map((vehicle) => {
            let _vehicleType = vehicle.VehicleType
              ? vehicle.VehicleType
              : 'Car';

            let _vehicleMatch = allVehicleList.filter(
              (_vehicle) =>
                _vehicle.make === vehicle.Make &&
                _vehicle.model === vehicle.Model
            )[0];

            if (
              _vehicleMatch &&
              _vehicleMatch.body_styles &&
              _vehicleMatch.body_styles.length
            ) {
              if (bodyStyleMap[_vehicleMatch.body_styles[0]]) {
                _vehicleType = bodyStyleMap[_vehicleMatch.body_styles[0]];
              } else {
                _vehicleType = _vehicleMatch.body_styles[0];
              }
            }

            return { ...vehicle, VehicleType: _vehicleType };
          })
        )
      );
    }
  }, [profileKey]);

  useEffect(() => {
    if (vehicleList) {
      let _filterDictionary = {
        View: {
          label: 'View',
          values: {},
          dropdown: true,
          selectedValue: null
        },
        Color: { all: true, label: 'Color', values: {}, fullList: colorList },
        VehicleType: {
          all: true,
          label: 'Type',
          values: {},
          selectedValue: null,
          fullList: ['Car', 'Truck', 'SUV', 'Van', 'Motorcycle']
        },
        Make: {
          all: true,
          label: 'Make',
          values: {},
          fullList: getMakeList().concat(['Other'])
        },
        Model: {
          all: true,
          label: 'Model',
          values: {},
          fullList: []
        }
      };

      vehicleList.map((vehicle) => {
        Object.keys(_filterDictionary).map((key) => {
          if (vehicle[key]) {
            vehicle[key].split(', ').map((value) => {
              if (!_filterDictionary[key].values[value]) {
                _filterDictionary[key].values[value] = {
                  total: 0
                };
              }

              _filterDictionary[key].values[value].total++;
            });
          } else if (key === 'View' && vehicle.FileList) {
            vehicle.FileList.split(',').map((descriptionFile) => {
              const description = descriptionFile.split('=>')[0];
              if (!_filterDictionary.View.values[description]) {
                _filterDictionary.View.values[description] = {
                  total: 0
                };
              }

              if (!_filterDictionary.View.selectedValue) {
                _filterDictionary.View.selectedValue = description;
              }

              _filterDictionary.View.values[description].total++;
            });
          }
        });
      });

      setFilterDictionary(_filterDictionary);

      setFilteredVehicleList(
        vehicleList.map((vehicle) => {
          return { ...vehicle };
        })
      );
    }
  }, [vehicleList]);

  useEffect(() => {
    if (filterDictionary) {
      setFilteredVehicleList(
        vehicleList.filter((vehicle) => checkPassesFilter(vehicle))
      );
    }
  }, [filterDictionary, searchString]);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      filteredVehicleList &&
      filtersClicked
    ) {
      const top = document.getElementById('filterListStart');
      if (top) {
        scrollToElementInPage(top, -145, false);
      }
    }
  }, [filteredVehicleList]);

  useEffect(() => {
    if (makeFilter) {
      updateModelFilter();
    }
  }, [makeFilter]);

  function checkPassesFilter(vehicle) {
    let passes = true;

    if (filterDictionary) {
      Object.keys(vehicle).map((column) => {
        if (filterDictionary[column] && filterDictionary[column].all !== true) {
          const values = vehicle[column];
          if (values) {
            let valuePasses = false;
            values.split(', ').map((value) => {
              if (filterDictionary[column].values[value] === true) {
                valuePasses = true;
              }
            });

            if (!valuePasses) {
              passes = false;
            }
          }
        }
      });
    }

    if (searchString && passes) {
      let passesSearch = false;

      let searchStringLowerCase = String(searchString).toLowerCase();

      if (residentPassesSearch({ ...vehicle }, searchString)) {
        passesSearch = true;
      } else if (
        vehicle.Make &&
        vehicle.Make.toLowerCase().indexOf(searchStringLowerCase) !== -1
      ) {
        passesSearch = true;
      } else if (
        vehicle.Model &&
        vehicle.Model.toLowerCase().indexOf(searchStringLowerCase) !== -1
      ) {
        passesSearch = true;
      } else if (
        vehicle.LicensePlateNumber &&
        vehicle.LicensePlateNumber.toLowerCase().indexOf(
          searchStringLowerCase
        ) !== -1
      ) {
        passesSearch = true;
      }

      if (!passesSearch) {
        passes = false;
      }
    }

    return passes;
  }

  function updateModelFilter() {
    if (filterDictionary) {
      let _modelList = [];
      if (!filterDictionary.Make.all) {
        Object.keys(filterDictionary.Make.values)
          .filter((value) => filterDictionary.Make.values[value] === true)
          .map((make) => {
            _modelList = _modelList.concat(
              getModelList(null, make).concat('Other')
            );
          });
      }

      setFilterDictionary({
        ...filterDictionary,
        Model: { ...filterDictionary.Model, fullList: _modelList }
      });
    }
  }

  return (
    <div className={'property-vehicles'}>
      {filteredVehicleList ? (
        <div className="property-filtered-list">
          <div className="filters">
            {filterDictionary && vehicleList ? (
              <div onClick={() => setFiltersClicked(true)}>
                {Object.keys(filterDictionary)
                  .filter(
                    (key) =>
                      (filterDictionary[key].fullList &&
                        filterDictionary[key].fullList.length) ||
                      filterDictionary[key].dropdown
                  )
                  .map((key) => (
                    <PropertyFilter
                      filterKey={key}
                      filterDictionary={filterDictionary}
                      setFilterDictionary={setFilterDictionary}
                    />
                  ))}
              </div>
            ) : null}
          </div>
          <div className="grid">
            <div id="filterListStart"></div>

            {filteredVehicleList.length ? (
              filteredVehicleList.map((vehicle) => (
                <PropertyFilterCard
                  key={vehicle.ResidentVehicleID}
                  unitNumber={vehicle.UnitNumber}
                  firstName={vehicle.FirstName}
                  lastName={vehicle.LastName}
                  fileListString={vehicle.FileList}
                  title={
                    vehicle.Model && vehicle.Model !== 'Other'
                      ? vehicle.Model
                      : vehicle.Make && vehicle.Make !== 'Other'
                      ? vehicle.Color + ' ' + vehicle.Make
                      : vehicle.Color + ' ' + vehicle.VehicleType
                  }
                  profileLabel={'Vehicle Profile'}
                  profileClick={() => setShowVehicleProfile(vehicle)}
                  descriptionFilter={filterDictionary.View.selectedValue}
                />
              ))
            ) : (
              <div className="property-filtered-list-empty">
                {vehicleList.length
                  ? 'No vehicles found matching the filters you selected.'
                  : 'No vehicles have been approved for this property.'}
              </div>
            )}
          </div>

          {showVehicleProfile ? (
            <VehicleProfile
              vehicle={showVehicleProfile}
              closeFunc={() => setShowVehicleProfile(null)}
              profileKey={profileKey}
            />
          ) : null}
        </div>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyVehicles);
