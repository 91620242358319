import React, { useEffect } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function PropertyFilter({
  filterKey,
  filterDictionary,
  setFilterDictionary
}) {
  return (
    <div className="filter">
      <FormControl component="fieldset">
        <FormLabel component="legend">
          {filterDictionary[filterKey].label}
        </FormLabel>

        {filterDictionary[filterKey].dropdown ? (
          <Select
            variant="standard"
            fullWidth
            value={filterDictionary[filterKey].selectedValue}
            onChange={(event) => {
              setFilterDictionary({
                ...filterDictionary,
                [filterKey]: {
                  ...filterDictionary[filterKey],
                  selectedValue: event.target.value
                }
              });
            }}>
            {Object.keys(filterDictionary[filterKey].values).map((value) => (
              <MenuItem value={value}>{value}</MenuItem>
            ))}
          </Select>
        ) : (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterDictionary[filterKey].all === true}
                  onChange={() => {
                    let _values = { ...filterDictionary[filterKey].values };

                    Object.keys(_values).map((key) => {
                      _values[key] = false;
                    });

                    setFilterDictionary({
                      ...filterDictionary,
                      [filterKey]: {
                        ...filterDictionary[filterKey],
                        all: true,
                        values: { ..._values }
                      }
                    });
                  }}
                  name="ALL"
                  color="primary"
                  disabled={filterDictionary[filterKey].all}
                />
              }
              label="ALL"
            />

            {filterDictionary[filterKey].fullList
              .filter(
                (value) =>
                  filterDictionary[filterKey].values[value] !== undefined
              )
              .map((value) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !filterDictionary[filterKey].all &&
                        filterDictionary[filterKey].values[value] === true
                      }
                      onChange={() => {
                        setFilterDictionary({
                          ...filterDictionary,
                          [filterKey]: {
                            ...filterDictionary[filterKey],
                            values: {
                              ...filterDictionary[filterKey].values,
                              [value]:
                                filterDictionary[filterKey].values[value] ===
                                true
                                  ? false
                                  : true
                            },
                            all:
                              Object.keys(
                                filterDictionary[filterKey].values
                              ).filter((_value) =>
                                _value === value
                                  ? filterDictionary[filterKey].values[
                                      value
                                    ] === true
                                    ? false
                                    : true
                                  : filterDictionary[filterKey].values[
                                      _value
                                    ] === true
                              ).length === 0
                          }
                        });
                      }}
                      name={value}
                      color="primary"
                    />
                  }
                  label={value}
                  //   disabled={
                  //     !filterCounts ||
                  //     !filterCounts.termLength ||
                  //     !filterCounts.termLength[termLength]
                  //   }
                />
              ))}
          </FormGroup>
        )}
      </FormControl>
    </div>
  );
}
