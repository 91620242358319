import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';

import LoginForm from './';

import './index.css';

export function LoginFormPopup(props) {
  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="user-login-popup"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            props.closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        User Login
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <LoginForm {...props} />
      </DialogContent>
    </Dialog>
  );
}
function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      userLoggedIn: bindActionCreators(userAuthActions.userLoggedIn, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormPopup);
