import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import { LinearProgress, IconButton, Button } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

import { scrollToElementInPage } from '../../api/dataApi';
import { listResidentPet } from '../../api/propertyProfileApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import Team from '../CompanyProfile/Team';

import '../PropertyFilterGrid/index.css';
import './index.css';

function PropertyContact({ profileKey, siteConfig }) {
  return (
    <div className="property-setup-section contact">
      {siteConfig.id === 'sc' ? (
        <Button
          style={{ float: 'left', width: '50px', marginRight: '-50px' }}
          onClick={() => navigate('/property-setup?property=' + profileKey)}>
          <ChevronLeft /> Back
        </Button>
      ) : null}
      <p>
        <strong>
          If you need help or have any questions,{' '}
          {siteConfig.id === 'mi'
            ? 'please reach out to one of our team members below'
            : 'feel free to ask:'}
        </strong>
      </p>
      <p>
        {siteConfig.id === 'mi' ? 'Or, call ' : 'Call '} us at:{' '}
        <a href={'tel:' + siteConfig.phone} className="link">
          {siteConfig.phone}
        </a>
      </p>
      <p>
        Or, email us at:{' '}
        <a href={'mailto:' + siteConfig.email} className="link">
          {siteConfig.email}
        </a>
      </p>
      <Team
        siteConfig={siteConfig}
        team={'sales'}
        selectedMember={siteConfig.id === 'mi' ? 'bud' : null}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyContact);
