import React from 'react';

import { withPrefix } from 'gatsby-link';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function PropertyProfileDefaults({ closeFunc, confirmFunc, method }) {
  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="usage-history-request"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        {method === 'clear' ? (
          <div>Clear Form</div>
        ) : (
          <div>Restore Defaults</div>
        )}
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        <p>
          Are you sure you want to{' '}
          {method === 'clear'
            ? 'clear the current settings'
            : 'restore the default options'}{' '}
          for this property? This action cannot be undone.
        </p>
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        <Button
          onClick={() => {
            confirmFunc();
          }}
          variant="outlined">
          Continue
        </Button>

        <Button
          onClick={() => {
            closeFunc();
          }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PropertyProfileDefaults;
