import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { LinearProgress, Button, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckIcon from '@mui/icons-material/Check';

import { validateEmail } from '../../api/userAuthApi';

import {
  listPropertyUser,
  savePropertyUser,
  deletePropertyUser
} from '../../api/propertyProfileApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import DemoModePopup from '../Dashboard/DemoModePopup';
import UserIcon from '../UserIcon';
import GeneralPopup from '../GeneralPopup';

const colorList = ['#f8a52b', '#337eff', '#9e38bf', '#01c364'];

function UserCard({
  user,
  setShowDemoModePopup,
  empty,
  setAddUser,
  setDeleteUser,
  authUser
}) {
  return empty ? (
    <div className="card user">No {empty} have been added.</div>
  ) : (
    <div className="card user">
      <UserIcon user={user} />
      {/* {user.UserID ? (
        <Button className="edit" onClick={() => setShowDemoModePopup(true)}>
          <EditIcon />
        </Button>
      ) : null} */}
      <h4>
        {user.FirstName} {user.LastName}
      </h4>
      <p>{user.Email}</p>
      <em>
        Last login:{' '}
        {user.LastLogin ? moment(user.LastLogin).format('MM/DD/YYYY') : 'Never'}
        {user.UserID !== authUser.User.UserID ? (
          <Button
            className="enable"
            variant="outlined"
            color="secondary"
            onClick={() => setDeleteUser({ ...user })}>
            Delete User
          </Button>
        ) : null}
        {!user.UserID && user.AgentID ? (
          <Button
            className="enable"
            variant="outlined"
            onClick={() => setAddUser({ ...user, IsAccountOwner: false })}>
            Give Access
          </Button>
        ) : null}
      </em>
    </div>
  );
}

function PropertyUsers({ siteConfig, authUser, profileKey }) {
  const [showDemoModePopup, setShowDemoModePopup] = useState(null);
  const [userList, setUserList] = useState(null);
  const [addUser, setAddUser] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);

  useEffect(() => {
    if (authUser) {
      if (checkIsDemo()) {
        setUserList({
          Admin: [
            {
              Email: 'demo@movingin.com',
              FirstName: 'Demo',
              LastName: 'Admin',
              LastLogin: moment(new Date()).format('MM/DD/YYYY'),
              ColorCode: '#ff3366'
            }
          ],
          Limited: [
            {
              Email: 'andrea@movingin.com',
              FirstName: 'Andrea',
              LastName: 'Blanchard',
              LastLogin: moment(new Date()).add(-2, 'day').format('MM/DD/YYYY'),
              ColorCode: colorList[0]
            },
            {
              Email: 'eve@movingin.com',
              FirstName: 'Eve',
              LastName: 'Rines',
              LastLogin: moment(new Date()).add(-5, 'day').format('MM/DD/YYYY'),
              ColorCode: colorList[1]
            },
            {
              Email: 'kim@movingin.com',
              FirstName: 'Kim',
              LastName: 'Marton',
              LastLogin: null,
              ColorCode: colorList[2]
            }
          ]
        });
      } else {
        loadUserList();
      }
    }
  }, [authUser]);

  function checkIsDemo() {
    return (
      !authUser || !authUser.User || authUser.User.Email === 'demo@movingin.com'
    );
  }

  function loadUserList() {
    listPropertyUser(profileKey).then((propertyUserList) => {
      setUserList({
        Admin: propertyUserList.filter((user) => user.UserLevelID >= 9),
        Limited: propertyUserList.filter(
          (user) => !user.UserLevelID || user.UserLevelID < 9
        )
      });
    });
  }

  return (
    <div className="property-setup-section users">
      <h2>User Access controls</h2>

      {userList ? (
        //&& authUser.User.Email === 'demo@movingin.com'
        <>
          <div className="user-list">
            <h3>
              <strong>
                {' '}
                <Button
                  onClick={() => {
                    if (checkIsDemo()) {
                      setShowDemoModePopup('Add Account User');
                    } else {
                      setAddUser({ IsAccountOwner: true });
                    }
                  }}>
                  + Add Account Owner
                </Button>{' '}
                Account Owner{' '}
              </strong>
              Account owners have full access to edit the Property Setup and add
              other users.
            </h3>

            {userList.Admin.length ? (
              userList.Admin.map((user) => (
                <UserCard
                  user={user}
                  setShowDemoModePopup={setShowDemoModePopup}
                  setAddUser={setAddUser}
                  setDeleteUser={setDeleteUser}
                  authUser={authUser}
                />
              ))
            ) : (
              <UserCard empty={'Account Owners'} />
            )}
          </div>

          <div className="user-list">
            <h3>
              <strong>
                <Button
                  onClick={() => {
                    if (checkIsDemo()) {
                      setShowDemoModePopup('Add Account User');
                    } else {
                      setAddUser({ IsAccountOwner: false });
                    }
                  }}>
                  + Add Account User
                </Button>
                Account User{' '}
              </strong>
              Account users have full view and upload capabilities but cannot
              edit the Property Setup or add other users.
            </h3>

            {userList.Limited.length ? (
              userList.Limited.map((user) => (
                <UserCard
                  user={user}
                  setShowDemoModePopup={setShowDemoModePopup}
                  setAddUser={setAddUser}
                  setDeleteUser={setDeleteUser}
                  authUser={authUser}
                />
              ))
            ) : (
              <UserCard empty={'Account Users'} />
            )}
          </div>
        </>
      ) : (
        <LinearProgress />
        // <Alert severity="warning" style={{ marginTop: '20px' }}>
        //   To change these settings, please contact your {siteConfig.brand}{' '}
        //   account manager at{' '}
        //   <a href={'tel:' + siteConfig.phone} className="link">
        //     {siteConfig.phone}
        //   </a>{' '}
        //   or email{' '}
        //   <a href={'mailto:' + siteConfig.email} className="link">
        //     {siteConfig.email}
        //   </a>
        //   .
        // </Alert>
      )}

      {showDemoModePopup ? (
        <DemoModePopup
          closeFunc={() => setShowDemoModePopup(null)}
          siteConfig={siteConfig}
          useContactForm
        />
      ) : null}

      {addUser ? (
        <GeneralPopup
          title={'Add User'}
          className={'property-user-add'}
          message={
            <>
              <div className="radio-container">
                <p>
                  <strong>Access Level:</strong>
                </p>

                <FormControl component="fieldset">
                  <RadioGroup
                    name="userType"
                    value={addUser.IsAccountOwner}
                    onChange={(event, newValue) => {
                      setAddUser({
                        ...addUser,
                        IsAccountOwner: newValue == 'true'
                      });
                    }}>
                    <FormControlLabel
                      value={false}
                      className={!addUser.IsAccountOwner ? 'selected' : ''}
                      control={<Radio color="primary" />}
                      label={<>Account User</>}
                    />
                    <FormControlLabel
                      value={true}
                      className={addUser.IsAccountOwner ? 'selected' : ''}
                      control={<Radio color="primary" />}
                      label={<>Account Owner</>}
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <TextField
                fullWidth
                label={'Email'}
                required
                type="email"
                value={addUser.Email}
                onChange={(event) =>
                  setAddUser({ ...addUser, Email: event.target.value })
                }
              />

              <TextField
                fullWidth
                required
                label={'First Name'}
                value={addUser.FirstName}
                onChange={(event) =>
                  setAddUser({ ...addUser, FirstName: event.target.value })
                }
              />

              <TextField
                fullWidth
                required
                label={'Last Name'}
                value={addUser.LastName}
                onChange={(event) =>
                  setAddUser({ ...addUser, LastName: event.target.value })
                }
              />

              <div className="radio-container">
                <p>
                  <strong>User Icon:</strong>{' '}
                  <ul
                    className={
                      'color-list' + (addUser.ColorCode ? ' has-selection' : '')
                    }>
                    {colorList.map((color) => (
                      <li>
                        <Button
                          style={{ backgroundColor: color }}
                          onClick={() =>
                            setAddUser({ ...addUser, ColorCode: color })
                          }
                          className={
                            addUser.ColorCode === color ? 'selected' : ''
                          }>
                          <span className="initials">
                            {addUser.FirstName
                              ? addUser.FirstName.substring(0, 1)
                              : 'A'}
                            {addUser.FirstName
                              ? addUser.LastName
                                ? addUser.LastName.substring(0, 1)
                                : ''
                              : 'A'}
                          </span>
                        </Button>
                      </li>
                    ))}
                  </ul>
                </p>
              </div>
            </>
          }
          closeFunc={() => setAddUser(null)}
          closeLabel={'Cancel'}
          submitDisabled={
            !addUser.FirstName ||
            !addUser.LastName ||
            !addUser.Email ||
            !addUser.ColorCode ||
            !validateEmail(addUser.Email) ||
            addUser.saving
          }
          submitLabel={addUser.saving ? 'Saving...' : 'Save'}
          submitFunc={() => {
            setAddUser({ ...addUser, saving: true });
            savePropertyUser(
              profileKey,
              addUser.Email,
              addUser.FirstName,
              addUser.LastName,
              addUser.ColorCode,
              addUser.AgentID,
              addUser.IsAccountOwner
            ).then((result) => {
              setAddUser(null);
              loadUserList();
            });
          }}
        />
      ) : null}

      {deleteUser ? (
        <GeneralPopup
          title={'Delete User'}
          className={'property-user-add'}
          message={
            <>
              <p style={{ marginTop: '20px' }}>
                Are you sure you want to delete this user? This action cannot be
                undone.
              </p>
            </>
          }
          closeFunc={() => setDeleteUser(null)}
          closeLabel={'Cancel'}
          submitLabel={deleteUser.deleting ? 'Deleting...' : 'Confirm Delete'}
          submitFunc={() => {
            setDeleteUser({ ...deleteUser, deleting: true });

            deletePropertyUser(
              profileKey,
              deleteUser.UserID,
              deleteUser.AgentID
            ).then((result) => {
              setDeleteUser(null);
              loadUserList();
            });
          }}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyUsers);
