import React, { useEffect, useRef, useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

export default function OtherPartnerField({
  field,
  serviceProfile,
  setServiceProfile
}) {
  return (
    <div className={'checkbox-option other-partner'}>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              serviceProfile.Header[field + 'Checked'] ||
              serviceProfile.Header[field]
            }
            onChange={(event) =>
              setServiceProfile({
                ...serviceProfile,
                Header: {
                  ...serviceProfile.Header,
                  [field]: '',
                  [field + 'Checked']: event.target.checked
                }
              })
            }
          />
        }
        label={
          <div
            className={
              !serviceProfile.Header[field + 'Checked'] &&
              !serviceProfile.Header[field]
                ? ' disabled'
                : ''
            }>
            Other Partner Program:
            <br />
            <br />
            <TextField
              value={serviceProfile.Header[field]}
              label="Partner Name"
              autoFocus={
                serviceProfile.Header[field + 'Checked'] &&
                !serviceProfile.Header[field]
              }
              onChange={(event) =>
                setServiceProfile({
                  ...serviceProfile,
                  Header: {
                    ...serviceProfile.Header,
                    [field]: event.target.value
                  }
                })
              }
              placeholder={'Type partner name here'}
            />
          </div>
        }
      />
    </div>
  );
}
